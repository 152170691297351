import { useParams } from "react-router-dom"

import ConnectionBoostPrepHome from "./ConnectionBoostPrepHome"
import MiniKitPrepHome from "./MiniKitPrepHome"
import SessionCompleteHome from "./SessionCompleteHome"
import SessionPrepHome from "./SessionPrepHome"
import TeamMemberHome from "./TeamMemberHome"

import NotFound from "components/NotFound"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import Loading from "ui/Loading"
import { useKitInstanceByID } from "ui/SelectedTeamContext"
import { KitType } from "utils/kit"

const LeaderKitDetail = () => {
  const { id } = useParams()
  const { kitInstance, isFetching: kitInstanceIsFetching } = useKitInstanceByID(id)
  const { data: user } = useUser({ userId: "me" })
  const { data: team } = useTeam({ teamId: kitInstance?.team_id })

  if (!kitInstance) {
    return kitInstanceIsFetching ? <Loading /> : <NotFound />
  }

  if (!user || !team) {
    return null
  }

  const PrepHome = getPrepHome(kitInstance.kit.type)

  if (team.demo && team.team_lead_id !== user.id) {
    return <PrepHome kitInstance={kitInstance} user={user} team={team} />
  }

  if (kitInstance.session_completed_at) {
    return <SessionCompleteHome kitInstance={kitInstance} user={user} />
  }

  if (team.team_lead_id === user.id) {
    return <PrepHome kitInstance={kitInstance} user={user} team={team} />
  } else {
    return <TeamMemberHome kitInstance={kitInstance} />
  }
}

const getPrepHome = (kitType) => {
  switch (kitType) {
    case KitType.BOOST:
      return ConnectionBoostPrepHome
    case KitType.MINI:
      return MiniKitPrepHome
    default:
      return SessionPrepHome
  }
}

export default LeaderKitDetail
