import { BasicTooltip } from "@nivo/tooltip"
import cn from "classnames"
import { last, sortBy } from "lodash-es"
import { styled } from "styled-components"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import BarChart from "ui/BarChart"
import useWindowSize from "ui/hooks/useWindowSize"
import { splitStrIntoLines } from "utils/string"

const RadioGroupCollectionMultiUserBarChart = ({ exerciseComponent, group_by, ...props }) => {
  const choiceOptions = exerciseComponent.options
  switch (group_by) {
    case "question":
      return <GroupByQuestionBarChart choiceOptions={choiceOptions} {...props} />
    default:
      throw new Error(`Invalid group_by ${group_by}`)
  }
}

const GroupByQuestionBarChart = styled(function GroupByQuestionBarChart({
  choiceOptions,
  exerciseInstances,
  identifiers,
  show_overall,
  className,
}) {
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  const latestExerciseInstance = last(sortBy(exerciseInstances, "created_at"))
  const keys = choiceOptions.map((o) => o.value)
  const colors = choiceOptions.map((o) => o.color)
  const data = identifiers
    .slice()
    .reverse()
    .map((identifier) => {
      const questionShortLabel = getExerciseComponent(latestExerciseInstance, identifier).short_label
      const answersCollection = exerciseInstances
        .map((exercise) => getExerciseAnswer(exercise, identifier))
        .filter(Boolean)
      const totalAnswers = answersCollection.length

      const choiceOptionCount = choiceOptions.reduce((acc, choiceOption) => {
        const value = choiceOption.value
        const count = answersCollection.filter((answer) => answer.includes(value)).length
        const ratio = count / totalAnswers
        return { [value]: ratio, ...acc }
      }, {})

      return {
        question: questionShortLabel,
        ...choiceOptionCount,
      }
    })

  if (show_overall) {
    const choiceOptionCount = choiceOptions.reduce((acc, choiceOption) => {
      const answersCollection = identifiers
        .flatMap((identifier) => exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)))
        .filter(Boolean)
      const totalAnswers = answersCollection.length

      const value = choiceOption.value
      const count = answersCollection.filter((answer) => answer.includes(value)).length
      const ratio = count / totalAnswers
      return { [value]: ratio, ...acc }
    }, {})

    data.push({
      question: "Overall",
      ...choiceOptionCount,
    })
  }

  const questionAxisFormatter = (questionShortLabel) => {
    const maxWidth = isMobileLandscapeOrSmaller ? 10 : 16
    const lines = splitStrIntoLines(questionShortLabel, maxWidth)
    return (
      <>
        {lines.map((line, idx) => {
          const dy0 = (lines.length - 1) * 0.5
          const dy = lines.length === 1 ? null : idx > 0 ? "1em" : `-${dy0}em`
          return (
            <tspan key={idx} x="0" dy={dy}>
              {line}
            </tspan>
          )
        })}
      </>
    )
  }

  const BarChartTooltip = ({ id, formattedValue }) => {
    const label = choiceOptions.find(({ value }) => value === id).short_label
    return <BasicTooltip id={label} value={formattedValue} />
  }

  return (
    <BarChart
      className={cn("mb-large", className)}
      data={data}
      keys={keys}
      indexBy="question"
      colors={colors}
      innerPadding={2}
      axisBottom={null}
      enableGridY={false}
      margin={{ left: isMobileLandscapeOrSmaller ? 90 : 200 }}
      axisLeft={{
        format: questionAxisFormatter,
        tickSize: 0,
        tickPadding: isMobileLandscapeOrSmaller ? 10 : 20,
      }}
      valueFormat={(value) => `${Math.round(value * 100)}%`}
      tooltip={BarChartTooltip}
    />
  )
})`
  width: 100%;
  height: 500px;
`

export default RadioGroupCollectionMultiUserBarChart
