import { Navigate } from "react-router"

import KitsTableOfContents from "./KitsTableofContents/KitsTableOfContents"

import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"

const LeaderKitHome = () => {
  const features = useFeatures()
  const { selectedTeamId, redirectTeamId, needsRedirect } = useSelectedTeamIdWithTeamIdQueryParam()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const { data: user } = useUser({ userId: "me" })

  if (redirectTeamId) {
    return <Navigate to={`/kit?team_id=${redirectTeamId}`} replace />
  }

  if (!user || !selectedTeam || needsRedirect) {
    return null
  }

  const isStandardTeam = !selectedTeam.preview && !selectedTeam.demo
  const isTeamLead = selectedTeam.team_lead_id === user.id

  // TODO(onboarding-launch): remove if statement including code within it
  if (features && !features[FLAGS.RTDEV_ONBOARDING]) {
    const needsOnboarding = isStandardTeam && isTeamLead && !user.onboarded_at
    if (needsOnboarding) {
      return <Navigate to={`/kit/onboarding?team_id=${selectedTeamId}`} replace />
    }
  }

  return (
    <main className="align-left full-width">
      <div className="main-container">
        <KitsTableOfContents user={user} selectedTeam={selectedTeam} />
      </div>
    </main>
  )
}

export default LeaderKitHome
