import cn from "classnames"
import { forwardRef } from "react"
import { Link, NavLink } from "react-router-dom"
import { styled } from "styled-components"

import { useSideBarMenuItems } from "./Sidebar"

import UserAccount from "domains/UserAccount/UserAccount"
import { BarsIcon, TimesIcon } from "icons/FontAwesomeIcons"
import BulletedList from "ui/BulletedList"
import Button from "ui/Button"
import useWindowSize from "ui/hooks/useWindowSize"
import Menu from "ui/Menu"
import { useModal } from "ui/ModalContext"
import View from "ui/View"

const UserMenu = ({ user, className }) => {
  const { sideBarMenuItems } = useSideBarMenuItems()
  const { isTabletOrSmaller } = useWindowSize()
  const { setModal } = useModal()

  if (!user) {
    return null
  }

  const openUserAccountModal = () => {
    // TODO(admin) replace below with !user.can_see_admin_tab when we have that
    const showTeamsInAccountModal = !(user.is_a_team_lead || user.can_manage_billing_for_an_account)
    setModal({
      title: "Edit account",
      content: <UserAccount hideTitle onDone={() => setModal(null)} />,
      size: showTeamsInAccountModal ? "medium" : "small",
    })
  }

  const { first_name, last_name, email } = user
  const nameInitials = first_name?.charAt(0).toUpperCase() + last_name?.charAt(0).toUpperCase()

  const MenuNavLink = forwardRef(({ children, className, ...props }, ref) => (
    <NavLink ref={ref} {...props} className={({ isActive }) => cn(className, { "is-active": isActive })}>
      {children}
    </NavLink>
  ))
  // Note: We need to use this forwardRef setup so React Router's NavLink isActive
  // prop can work to add the "is-active" className. Using className with isActive
  // on <Menu.Item as={NavLink} ...> components below doesn't work.
  // See: https://reactrouter.com/en/6.14.2/upgrading/v5#remove-activeclassname-and-activestyle-props-from-navlink-

  return (
    <Menu className={cn("text-small", className)}>
      {!!nameInitials && (
        <Menu.Button
          className="hide-on-mobile menu-button border-none text-bold"
          style={{ backgroundColor: "var(--orange-3)", color: "#fff" }}
        >
          <View $justifyContent="center">{nameInitials}</View>
        </Menu.Button>
      )}
      <Menu.Button className={cn("border-none", { "show-on-mobile": nameInitials })}>
        <BarsIcon width="24px" height="24px" color="var(--gray-9)" className="fa-xl" />
      </Menu.Button>
      <div>
        <Menu.Items>
          <Menu.Item className="text-default text-bold">
            <View $alignItems="center" $justifyContent="space-between">
              <span className="text-gray-9 text-bold">Account</span>
              <TimesIcon color="var(--gray-6)" className="fa-lg cursor-pointer" />
            </View>
          </Menu.Item>
          <Menu.Item className="border-top">
            <View className="mt-xs">
              {!!nameInitials && (
                <View $alignItems="center" $justifyContent="center" className="name-initial-icon text-bold">
                  {nameInitials}
                </View>
              )}
              <View $flexDirection="column" className="pl-small">
                <View>
                  <span className="text-gray-9">{first_name}</span>
                  <span className="text-gray-9 pl-xxs">{last_name}</span>
                  <Button className="link-blue text-small ml-auto neg-my-xs" onClick={openUserAccountModal}>
                    Edit
                  </Button>
                </View>
                <div className="text-gray-8">{email}</div>
              </View>
            </View>
          </Menu.Item>
          {sideBarMenuItems.map((item, index) => (
            <Menu.Item
              as={MenuNavLink}
              className={cn("menu-link hide-on-desktop", { "border-top": index > 0 })}
              to={item.path}
              key={index}
            >
              {item.text}
            </Menu.Item>
          ))}
          <Menu.Item
            as="a"
            className={cn("menu-link hide-on-desktop", { "border-top": isTabletOrSmaller })}
            href="https://risingteam.com/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </Menu.Item>
          <Menu.Item as={MenuNavLink} className={"menu-link border-top"} to="/contact">
            Contact us
          </Menu.Item>
          <Menu.Item className="pb-none border-top menu-link">Help</Menu.Item>
          <Menu.Item>
            <BulletedList>
              <li>
                <a
                  href="https://risingteam.com/resources/facilitator-guide"
                  className="menu-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facilitator guide
                </a>
              </li>
              <li className="menu-bullet-list-item">
                <a
                  href="https://risingteam.com/resources/team-lead-guide"
                  className="menu-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Team lead guide
                </a>
              </li>
              <li className="menu-bullet-list-item">
                <a
                  href="https://risingteam.com/resources/account-admin-guide"
                  className="menu-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Admin guide
                </a>
              </li>
              <li className="menu-bullet-list-item">
                <a
                  href="https://risingteam.com/resources/pilot-guide"
                  className="menu-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pilot guide
                </a>
              </li>
            </BulletedList>
          </Menu.Item>
          <Menu.Item as={Link} className="border-top menu-link" to="/auth/logout">
            Sign out
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  )
}

export default styled(UserMenu)`
  .menu-button,
  .name-initial-icon {
    border-radius: 50%;
    min-width: 3rem;
    min-height: 3rem;
    width: 3rem;
    height: 3rem;
  }

  .menu-button {
    box-shadow: var(--blur-4);

    &:hover {
      box-shadow: var(--lift-6);
    }
  }

  .menu-link {
    font-weight: 500;
  }

  a.menu-link:hover {
    font-weight: 600;
  }

  .name-initial-icon {
    background-color: var(--orange-3);
    color: #fff;
  }

  .is-active {
    color: var(--gray-9);
    font-weight: 600;
  }
`
