import { Field, ErrorMessage } from "formik"

import View from "ui/View"

const ToggleField = ({ children, name, ...props }) => (
  <>
    <View as="label" className="text-small">
      <Field
        type="checkbox"
        className="less-box-shadow border-gray-5 border-radius mt-xxs mr-small"
        name={name}
        {...props}
      />
      {children}
    </View>
    <ErrorMessage component="div" className="text-danger" data-cy="field-message" name={name} />
  </>
)

export default ToggleField
