import { styled } from "styled-components"

import ArtiConversationTypeSelectField from "./ArtiConversationTypeSelectField"

import View from "ui/View"

const ArtiConversationTypeSelectorMessage = styled(function ArtiConversationTypeSelectorMessage({
  className,
  conversationTypeSelected,
  onChange,
  onStartNewConversation,
}) {
  return (
    <View $flexDirection="column" className="flex-shrink-0">
      <div className="text-right mb-xxs text-semi-bold full-width">you</div>
      <div className="mb-medium pr-medium py-small message user full-width">
        <ArtiConversationTypeSelectField
          className={className}
          onChange={onChange}
          conversationTypeSelected={conversationTypeSelected}
          onStartNewConversation={onStartNewConversation}
        />
      </div>
    </View>
  )
})``

export default ArtiConversationTypeSelectorMessage
