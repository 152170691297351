import cn from "classnames"
import { styled } from "styled-components"

import { ArtiSender, VANILLA_LLM_TYPES, ARTI_RATING_NA_VALUE, MIN_RATEABLE_MESSAGE_LENGTH } from "../constants"
import { updateArtiExchangeRating } from "../resource"

import ArtiBotMessage from "./ArtiBotMessage"
import ArtiCopyButton from "./ArtiCopyButton"
import ArtiFlagButton from "./ArtiFlagButton"

import { removeLineRatingFieldNameSuffix } from "forms/fields/LineRatingField"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const ArtiChatMessages = styled(function ArtiChatMessages({
  messages,
  user,
  team,
  loading,
  showTextInputFeedback,
  llm,
  historyExchangeId,
  scrollToLoadedMessageRef,
  chatInputRef,
  className,
}) {
  const { enabled: hideRatingFeedback, isInitialLoading: hideRatingFeedbackLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.ARTI_HIDE_RATING_FEEDBACK
  )
  const { enabled: showKitRecommendations } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_ARTI_KIT_RECS)

  const showRatingFeedback = !hideRatingFeedback && !hideRatingFeedbackLoading && !team.demo

  function getOnRatingFunction({ exchangeId }) {
    return async function onRating(name, value) {
      const fieldNameWithoutSuffix = removeLineRatingFieldNameSuffix(name)
      await updateArtiExchangeRating(exchangeId, {
        [fieldNameWithoutSuffix]: value === ARTI_RATING_NA_VALUE ? -1 : value,
      })
    }
  }

  return (
    <div className={cn(className, "mb-medium")}>
      {messages.map((message, index) => (
        <div key={index}>
          {message.sender === ArtiSender.BOT ? (
            <div className="mb-xxs text-semi-bold">
              <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
              <span className="ml-xxs">aRTi</span>
            </div>
          ) : (
            <div className="text-right mb-xxs text-semi-bold">you</div>
          )}
          <div className={cn("mb-medium px-medium py-small message", message.sender)}>
            {!!index && message.sender === ArtiSender.BOT && (
              <View className="icon-buttons">
                <ArtiFlagButton exchangeId={message.exchangeId} initialValue={message.inappropriateFlag} />
                <ArtiCopyButton clipboardText={message.text} className="text-gray-7-important" />
              </View>
            )}
            {message.sender === ArtiSender.BOT ? (
              <ArtiBotMessage
                message={message}
                showLoading={!!loading && index === messages.length - 1}
                showKitRecommendations={showKitRecommendations}
                showRatingFields={
                  !!showRatingFeedback &&
                  index > 0 &&
                  VANILLA_LLM_TYPES.includes(llm) &&
                  message?.text?.length >= MIN_RATEABLE_MESSAGE_LENGTH
                }
                showTextInputFeedback={!!showTextInputFeedback}
                onRating={getOnRatingFunction({ exchangeId: message.exchangeId })}
                onKeyPressFocusElement={chatInputRef.current}
                user={user}
                team={team}
                messageIndex={index}
              />
            ) : (
              <div
                className={cn("text-prewrap", {
                  "arti-message-highlighted": message.exchangeId === historyExchangeId,
                })}
                ref={message.exchangeId === historyExchangeId ? scrollToLoadedMessageRef : null}
              >
                {message.text?.trim()}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
})``

export default ArtiChatMessages
