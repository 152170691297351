import { faSpinnerThird as faSpinnerThirdDuotone } from "@fortawesome/pro-duotone-svg-icons"
import { faBars } from "@fortawesome/pro-light-svg-icons"
import { faCalendar, faCircleInfo } from "@fortawesome/pro-regular-svg-icons"
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngry,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsRepeat,
  faArrowUp,
  faArrowUpRightFromSquare,
  faAsterisk,
  faAward,
  faBallPile,
  faBlender,
  faBlockQuestion,
  faBold,
  faBolt,
  faBook,
  faBookOpen,
  faBoxTissue,
  faBowlingPins,
  faBrain,
  faBriefcase,
  faBrightness,
  faBuilding,
  faBullseye,
  faBullseyePointer,
  faCalendar as faCalendarSolid,
  faCalendarStar,
  faCamera,
  faCameraWeb,
  faChairOffice,
  faChartLine,
  faChartMixed,
  faChartUser,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCloud,
  faCloudArrowUp,
  faCloudSun,
  faImage,
  faInfinity,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleExclamation,
  faCircleHalfStroke,
  faCirclePause,
  faCompass,
  faCookieBite,
  faClock,
  faCog,
  faCommentAlt,
  faCommentCheck,
  faCommentQuestion,
  faComments,
  faCopy,
  faDash,
  faDesktop,
  faDiagramProject,
  faDoNotEnter,
  faDog,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faDroplet,
  faDumbbell,
  faEar,
  // This icon became pen-to-square with v. 6 of fa
  faEdit,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEquals,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeLowVision,
  faEyeSlash,
  faFaceAngry,
  faFaceConfounded,
  faFaceFearful,
  faFaceFrown,
  faFaceGrimace,
  faFaceGrinHearts,
  faFaceGrinStars,
  faFaceHandYawn,
  faFaceLaughBeam,
  faFaceMeh,
  faFaceMehBlank,
  faFaceScream,
  faFaceSleeping,
  faFaceSmile,
  faFaceSmilingHands,
  faFaceSpiralEyes,
  faFaceSunglasses,
  faFaceThinking,
  faFaceUnamused,
  faFaceZipper,
  faFamily,
  faFile,
  faFingerprint,
  faFire,
  faFireAlt,
  faFireFlameSimple,
  faFishFins,
  faFlag,
  faForkKnife,
  faFrown,
  faFaceFrownSlight,
  faGamepadModern,
  faGavel,
  faGift,
  faGlass,
  faGolfBallTee,
  faGrinStars,
  faHand,
  faHandHoldingHand,
  faHandHoldingHeart,
  faHandPointRibbon,
  faHandPointUp,
  faHands,
  faHandsClapping,
  faHandshake,
  faHandsHoldingCircle,
  faHandsHoldingHeart,
  faHandWave,
  faHatWizard,
  faHeadSide,
  faHeadSideHeadphones,
  faHeadphones,
  faHeart,
  faHeartCircleCheck,
  faHeartPulse,
  faHome,
  faHourglassStart,
  faHouse,
  faIceCream,
  faIcons,
  faInfoCircle,
  faItalic,
  faLaptopMobile,
  faLightbulb,
  faLightbulbOn,
  faLink,
  faLinkSlash,
  faList,
  faListOl,
  faListCheck,
  faLock,
  faLockKeyhole,
  faLungs,
  faMagnifyingGlass,
  faMask,
  faMeh,
  faMessages,
  faMessagesQuestion,
  faMicrophoneLines,
  faMinus,
  faMobile,
  faMountains,
  faMegaphone,
  faMug,
  faMugTea,
  faMusic,
  faPaperPlane,
  faPaperPlaneTop,
  faPartyHorn,
  faPause,
  faPenAlt, // This icon became pen-clip with v. 6 of fa
  faPencil,
  faPenToSquare,
  faPeopleGroup,
  faPersonRays,
  faPhoneRotary,
  faPersonSkiJumping,
  faPlane,
  faPlay,
  faPlayCircle,
  faPlug,
  faPlus,
  faPrint,
  faQuestion,
  faRepeat,
  faRadio,
  faRoad,
  faRocketLaunch,
  faRunning,
  faSeedling,
  faSave,
  faSchool,
  faScrollOld,
  faShare,
  faShieldCheck,
  faShieldHeart,
  faShield,
  faShoePrints,
  faSirenOn,
  faSlash,
  faSmile,
  faSmileWink,
  faSnooze,
  faSquare,
  faSpa,
  faSparkles,
  faSpinner,
  faSquareCheck,
  faStar,
  faStars,
  faStarShooting,
  faStop,
  faSunrise,
  faTabletScreenButton,
  faTemperatureThreeQuarters,
  faToolbox,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faToiletPaperAlt,
  faTrafficCone,
  faTrafficLightStop,
  faTrafficLightSlow,
  faTrafficLightGo,
  faTrashCan,
  faTrashXmark,
  faTree,
  faTriangle,
  faTriangleExclamation,
  faTrophy,
  faTruckClock,
  faTvRetro,
  faUfo,
  faUnlock,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserSecret,
  faUserShield,
  faUsers,
  faUsersCog,
  faVideo,
  faVolume,
  faVolumeHigh,
  faVolumeLow,
  faVolumeSlash,
  faWandMagicSparkles,
  faWhistle,
  faWindowFrame,
  faXmark,
  faTrain,
  faElephant,
  faCrown,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AngleDownIcon = (props) => <FontAwesomeIcon icon={faAngleDown} {...props} />
const AngleLeftIcon = (props) => <FontAwesomeIcon icon={faAngleLeft} {...props} />
const AngleRightIcon = (props) => <FontAwesomeIcon icon={faAngleRight} {...props} />
const AngleUpIcon = (props) => <FontAwesomeIcon icon={faAngleUp} {...props} />
const AngryIcon = (props) => <FontAwesomeIcon icon={faAngry} {...props} />
const ArrowDownIcon = (props) => <FontAwesomeIcon icon={faArrowDown} {...props} />
const ArrowLeftIcon = (props) => <FontAwesomeIcon icon={faArrowLeft} {...props} />
const ArrowRightIcon = (props) => <FontAwesomeIcon icon={faArrowRight} {...props} />
const ArrowsRepeatIcon = (props) => <FontAwesomeIcon icon={faArrowsRepeat} {...props} />
const ArrowUpIcon = (props) => <FontAwesomeIcon icon={faArrowUp} {...props} />
const ArrowUpRightFromSquareIcon = (props) => <FontAwesomeIcon icon={faArrowUpRightFromSquare} {...props} />
const AsteriskIcon = (props) => <FontAwesomeIcon icon={faAsterisk} {...props} />
const AwardIcon = (props) => <FontAwesomeIcon icon={faAward} {...props} />
const BallPileIcon = (props) => <FontAwesomeIcon icon={faBallPile} {...props} />
const BarsIcon = (props) => <FontAwesomeIcon icon={faBars} {...props} />
const BlenderIcon = (props) => <FontAwesomeIcon icon={faBlender} {...props} />
const BlockQuestionIcon = (props) => <FontAwesomeIcon icon={faBlockQuestion} {...props} />
const BoldIcon = (props) => <FontAwesomeIcon icon={faBold} {...props} />
const BoltIcon = (props) => <FontAwesomeIcon icon={faBolt} {...props} />
const BookIcon = (props) => <FontAwesomeIcon icon={faBook} {...props} />
const BookOpenIcon = (props) => <FontAwesomeIcon icon={faBookOpen} {...props} />
const BoxTissueIcon = (props) => <FontAwesomeIcon icon={faBoxTissue} {...props} />
const BowlingPinsIcon = (props) => <FontAwesomeIcon icon={faBowlingPins} {...props} />
const BrainIcon = (props) => <FontAwesomeIcon icon={faBrain} {...props} />
const BriefcaseIcon = (props) => <FontAwesomeIcon icon={faBriefcase} {...props} />
const BrightnessIcon = (props) => <FontAwesomeIcon icon={faBrightness} {...props} />
const BuildingIcon = (props) => <FontAwesomeIcon icon={faBuilding} {...props} />
const BullseyeIcon = (props) => <FontAwesomeIcon icon={faBullseye} {...props} />
const BullseyePointerIcon = (props) => <FontAwesomeIcon icon={faBullseyePointer} {...props} />
const CalendarIcon = (props) => <FontAwesomeIcon icon={faCalendar} {...props} />
const CalendarSolidIcon = (props) => <FontAwesomeIcon icon={faCalendarSolid} {...props} />
const CalendarStarIcon = (props) => <FontAwesomeIcon icon={faCalendarStar} {...props} />
const CameraIcon = (props) => <FontAwesomeIcon icon={faCamera} {...props} />
const CameraWebIcon = (props) => <FontAwesomeIcon icon={faCameraWeb} {...props} />
const ChartLineIcon = (props) => <FontAwesomeIcon icon={faChartLine} {...props} />
const ChartMixedIcon = (props) => <FontAwesomeIcon icon={faChartMixed} {...props} />
const ChartUserIcon = (props) => <FontAwesomeIcon icon={faChartUser} {...props} />
const CheckIcon = (props) => <FontAwesomeIcon icon={faCheck} {...props} />
const ChevronDownIcon = (props) => <FontAwesomeIcon icon={faChevronDown} {...props} />
const ChevronLeftIcon = (props) => <FontAwesomeIcon icon={faChevronLeft} {...props} />
const ChevronUpIcon = (props) => <FontAwesomeIcon icon={faChevronUp} {...props} />
const CircleIcon = (props) => <FontAwesomeIcon icon={faCircle} {...props} />
const CircleCheckIcon = (props) => <FontAwesomeIcon icon={faCircleCheck} {...props} />
const CloudIcon = (props) => <FontAwesomeIcon icon={faCloud} {...props} />
const CloudArrowUpIcon = (props) => <FontAwesomeIcon icon={faCloudArrowUp} {...props} />
const CloudSunIcon = (props) => <FontAwesomeIcon icon={faCloudSun} {...props} />
const CircleChevronLeftIcon = (props) => <FontAwesomeIcon icon={faCircleChevronLeft} {...props} />
const CircleChevronRightIcon = (props) => <FontAwesomeIcon icon={faCircleChevronRight} {...props} />
const CircleExclamationIcon = (props) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />
const CircleHalfStrokeIcon = (props) => <FontAwesomeIcon icon={faCircleHalfStroke} {...props} />
const CircleInfoIcon = (props) => <FontAwesomeIcon icon={faCircleInfo} {...props} />
const CirclePauseIcon = (props) => <FontAwesomeIcon icon={faCirclePause} {...props} />
const ClockIcon = (props) => <FontAwesomeIcon icon={faClock} {...props} />
const CompassIcon = (props) => <FontAwesomeIcon icon={faCompass} {...props} />
const CookieBiteIcon = (props) => <FontAwesomeIcon icon={faCookieBite} {...props} />
const CogIcon = (props) => <FontAwesomeIcon icon={faCog} {...props} />
const CommentAltIcon = (props) => <FontAwesomeIcon icon={faCommentAlt} {...props} />
const CommentCheckIcon = (props) => <FontAwesomeIcon icon={faCommentCheck} {...props} />
const CommentQuestionIcon = (props) => <FontAwesomeIcon icon={faCommentQuestion} {...props} />
const CommentsIcon = (props) => <FontAwesomeIcon icon={faComments} {...props} />
const CopyIcon = (props) => <FontAwesomeIcon icon={faCopy} {...props} />
const DashIcon = (props) => <FontAwesomeIcon icon={faDash} {...props} />
const DesktopIcon = (props) => <FontAwesomeIcon icon={faDesktop} {...props} />
const DiagramProjectIcon = (props) => <FontAwesomeIcon icon={faDiagramProject} {...props} />
const DoNotEnterIcon = (props) => <FontAwesomeIcon icon={faDoNotEnter} {...props} />
const DogIcon = (props) => <FontAwesomeIcon icon={faDog} {...props} />
const DoorClosedIcon = (props) => <FontAwesomeIcon icon={faDoorClosed} {...props} />
const DoorOpenIcon = (props) => <FontAwesomeIcon icon={faDoorOpen} {...props} />
const DownloadIcon = (props) => <FontAwesomeIcon icon={faDownload} {...props} />
const DropletIcon = (props) => <FontAwesomeIcon icon={faDroplet} {...props} />
const DumbbellIcon = (props) => <FontAwesomeIcon icon={faDumbbell} {...props} />
const EarIcon = (props) => <FontAwesomeIcon icon={faEar} {...props} />
const EditIcon = (props) => <FontAwesomeIcon icon={faEdit} {...props} />
const EnvelopeOpenIcon = (props) => <FontAwesomeIcon icon={faEnvelopeOpen} {...props} />
const ElephantIcon = (props) => <FontAwesomeIcon icon={faElephant} {...props} />
const EnvelopeOpenTextIcon = (props) => <FontAwesomeIcon icon={faEnvelopeOpenText} {...props} />
const EqualsIcon = (props) => <FontAwesomeIcon icon={faEquals} {...props} />
const ExclamationCircleIcon = (props) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />
const ExclamationTriangleIcon = (props) => <FontAwesomeIcon icon={faExclamationTriangle} {...props} />
const EyeIcon = (props) => <FontAwesomeIcon icon={faEye} {...props} />
const EyeLowVisionIcon = (props) => <FontAwesomeIcon icon={faEyeLowVision} {...props} />
const EyeSlashIcon = (props) => <FontAwesomeIcon icon={faEyeSlash} {...props} />
const FaceAngryIcon = (props) => <FontAwesomeIcon icon={faFaceAngry} {...props} />
const FaceConfoundedIcon = (props) => <FontAwesomeIcon icon={faFaceConfounded} {...props} />
const FaceFearfulIcon = (props) => <FontAwesomeIcon icon={faFaceFearful} {...props} />
const FaceFrownIcon = (props) => <FontAwesomeIcon icon={faFaceFrown} {...props} />
const FaceGrimaceIcon = (props) => <FontAwesomeIcon icon={faFaceGrimace} {...props} />
const FaceGrinHeartsIcon = (props) => <FontAwesomeIcon icon={faFaceGrinHearts} {...props} />
const FaceGrinStarsIcon = (props) => <FontAwesomeIcon icon={faFaceGrinStars} {...props} />
const FaceHandYawnIcon = (props) => <FontAwesomeIcon icon={faFaceHandYawn} {...props} />
const FaceLaughBeamIcon = (props) => <FontAwesomeIcon icon={faFaceLaughBeam} {...props} />
const FaceMehIcon = (props) => <FontAwesomeIcon icon={faFaceMeh} {...props} />
const FaceMehBlankIcon = (props) => <FontAwesomeIcon icon={faFaceMehBlank} {...props} />
const FaceScreamIcon = (props) => <FontAwesomeIcon icon={faFaceScream} {...props} />
const FaceSleepingIcon = (props) => <FontAwesomeIcon icon={faFaceSleeping} {...props} />
const FaceSmileIcon = (props) => <FontAwesomeIcon icon={faFaceSmile} {...props} />
const FaceSmilingHandsIcon = (props) => <FontAwesomeIcon icon={faFaceSmilingHands} {...props} />
const FaceSpiralEyesIcon = (props) => <FontAwesomeIcon icon={faFaceSpiralEyes} {...props} />
const FaceSunglassesIcon = (props) => <FontAwesomeIcon icon={faFaceSunglasses} {...props} />
const FaceThinkingIcon = (props) => <FontAwesomeIcon icon={faFaceThinking} {...props} />
const FaceUnamusedIcon = (props) => <FontAwesomeIcon icon={faFaceUnamused} {...props} />
const FaceZipperIcon = (props) => <FontAwesomeIcon icon={faFaceZipper} {...props} />
const FamilyIcon = (props) => <FontAwesomeIcon icon={faFamily} {...props} />
const FileIcon = (props) => <FontAwesomeIcon icon={faFile} {...props} />
const FingerprintIcon = (props) => <FontAwesomeIcon icon={faFingerprint} {...props} />
const FireIcon = (props) => <FontAwesomeIcon icon={faFire} {...props} />
const FireAltIcon = (props) => <FontAwesomeIcon icon={faFireAlt} {...props} />
const FireFlameSimpleIcon = (props) => <FontAwesomeIcon icon={faFireFlameSimple} {...props} />
const FishFinsIcon = (props) => <FontAwesomeIcon icon={faFishFins} {...props} />
const FlagIcon = (props) => <FontAwesomeIcon icon={faFlag} {...props} />
const ForkKnifeIcon = (props) => <FontAwesomeIcon icon={faForkKnife} {...props} />
const FrownIcon = (props) => <FontAwesomeIcon icon={faFrown} {...props} />
const FrownSlightIcon = (props) => <FontAwesomeIcon icon={faFaceFrownSlight} {...props} />
const GamepadModernIcon = (props) => <FontAwesomeIcon icon={faGamepadModern} {...props} />
const GavelIcon = (props) => <FontAwesomeIcon icon={faGavel} {...props} />
const GiftIcon = (props) => <FontAwesomeIcon icon={faGift} {...props} />
const GlassIcon = (props) => <FontAwesomeIcon icon={faGlass} {...props} />
const GolfBallTeeIcon = (props) => <FontAwesomeIcon icon={faGolfBallTee} {...props} />
const GrinStarsIcon = (props) => <FontAwesomeIcon icon={faGrinStars} {...props} />
const HandHoldingHandIcon = (props) => <FontAwesomeIcon icon={faHandHoldingHand} {...props} />
const HandHoldingHeartIcon = (props) => <FontAwesomeIcon icon={faHandHoldingHeart} {...props} />
const HandPointRibbonIcon = (props) => <FontAwesomeIcon icon={faHandPointRibbon} {...props} />
const HandPointUpIcon = (props) => <FontAwesomeIcon icon={faHandPointUp} {...props} />
const HandIcon = (props) => <FontAwesomeIcon icon={faHand} {...props} />
const HandsIcon = (props) => <FontAwesomeIcon icon={faHands} {...props} />
const HandsClappingIcon = (props) => <FontAwesomeIcon icon={faHandsClapping} {...props} />
const HandshakeIcon = (props) => <FontAwesomeIcon icon={faHandshake} {...props} />
const HandsHoldingCircleIcon = (props) => <FontAwesomeIcon icon={faHandsHoldingCircle} {...props} />
const HandsHoldingHeartIcon = (props) => <FontAwesomeIcon icon={faHandsHoldingHeart} {...props} />
const HandWaveIcon = (props) => <FontAwesomeIcon icon={faHandWave} {...props} />
const HeadSideIcon = (props) => <FontAwesomeIcon icon={faHeadSide} {...props} />
const HeadSideHeadphonesIcon = (props) => <FontAwesomeIcon icon={faHeadSideHeadphones} {...props} />
const HeadphonesIcon = (props) => <FontAwesomeIcon icon={faHeadphones} {...props} />
const HeartCircleCheckIcon = (props) => <FontAwesomeIcon icon={faHeartCircleCheck} {...props} />
const HeartIcon = (props) => <FontAwesomeIcon icon={faHeart} {...props} />
const HeartPulseIcon = (props) => <FontAwesomeIcon icon={faHeartPulse} {...props} />
const HomeIcon = (props) => <FontAwesomeIcon icon={faHome} {...props} />
const HourglassStartIcon = (props) => <FontAwesomeIcon icon={faHourglassStart} {...props} />
const HouseIcon = (props) => <FontAwesomeIcon icon={faHouse} {...props} />
const IceCreamIcon = (props) => <FontAwesomeIcon icon={faIceCream} {...props} />
const IconsIcon = (props) => <FontAwesomeIcon icon={faIcons} {...props} />
const ImageIcon = (props) => <FontAwesomeIcon icon={faImage} {...props} />
const InfoCircleIcon = (props) => <FontAwesomeIcon icon={faInfoCircle} {...props} />
const InfinityIcon = (props) => <FontAwesomeIcon icon={faInfinity} {...props} />
const ItalicIcon = (props) => <FontAwesomeIcon icon={faItalic} {...props} />
const LaptopMobileIcon = (props) => <FontAwesomeIcon icon={faLaptopMobile} {...props} />
const LightbulbIcon = (props) => <FontAwesomeIcon icon={faLightbulb} {...props} />
const LightbulbOnIcon = (props) => <FontAwesomeIcon icon={faLightbulbOn} {...props} />
const LinkIcon = (props) => <FontAwesomeIcon icon={faLink} {...props} />
const LinkSlashIcon = (props) => <FontAwesomeIcon icon={faLinkSlash} {...props} />
const ListIcon = (props) => <FontAwesomeIcon icon={faList} {...props} />
const ListOlIcon = (props) => <FontAwesomeIcon icon={faListOl} {...props} />
const ListCheckIcon = (props) => <FontAwesomeIcon icon={faListCheck} {...props} />
const LockIcon = (props) => <FontAwesomeIcon icon={faLock} {...props} />
const LockKeyholeIcon = (props) => <FontAwesomeIcon icon={faLockKeyhole} {...props} />
const LungsIcon = (props) => <FontAwesomeIcon icon={faLungs} {...props} />
const MagnifyingGlassIcon = (props) => <FontAwesomeIcon icon={faMagnifyingGlass} {...props} />
const MaskIcon = (props) => <FontAwesomeIcon icon={faMask} {...props} />
const MehIcon = (props) => <FontAwesomeIcon icon={faMeh} {...props} />
const MessagesIcon = (props) => <FontAwesomeIcon icon={faMessages} {...props} />
const MessagesQuestionIcon = (props) => <FontAwesomeIcon icon={faMessagesQuestion} {...props} />
const MicrophoneLinesIcon = (props) => <FontAwesomeIcon icon={faMicrophoneLines} {...props} />
const MinusIcon = (props) => <FontAwesomeIcon icon={faMinus} {...props} />
const MobileIcon = (props) => <FontAwesomeIcon icon={faMobile} {...props} />
const MountainsIcon = (props) => <FontAwesomeIcon icon={faMountains} {...props} />
const MugIcon = (props) => <FontAwesomeIcon icon={faMug} {...props} />
const MugTeaIcon = (props) => <FontAwesomeIcon icon={faMugTea} {...props} />
const MusicIcon = (props) => <FontAwesomeIcon icon={faMusic} {...props} />
const MegaphoneIcon = (props) => <FontAwesomeIcon icon={faMegaphone} {...props} />
const OfficeChairIcon = (props) => <FontAwesomeIcon icon={faChairOffice} {...props} />
const PersonSkiJumpingIcon = (props) => <FontAwesomeIcon icon={faPersonSkiJumping} {...props} />
const PaperPlaneIcon = (props) => <FontAwesomeIcon icon={faPaperPlane} {...props} />
const PaperPlaneTopIcon = (props) => <FontAwesomeIcon icon={faPaperPlaneTop} {...props} />
const PartyHornIcon = (props) => <FontAwesomeIcon icon={faPartyHorn} {...props} />
const PauseIcon = (props) => <FontAwesomeIcon icon={faPause} {...props} />
const PenAltIcon = (props) => <FontAwesomeIcon icon={faPenAlt} {...props} />
const PencilIcon = (props) => <FontAwesomeIcon icon={faPencil} {...props} />
const PenToSquareIcon = (props) => <FontAwesomeIcon icon={faPenToSquare} {...props} />
const PeopleGroupIcon = (props) => <FontAwesomeIcon icon={faPeopleGroup} {...props} />
const PersonRaysIcon = (props) => <FontAwesomeIcon icon={faPersonRays} {...props} />
const PlaneIcon = (props) => <FontAwesomeIcon icon={faPlane} {...props} />
const PlayCircleIcon = (props) => <FontAwesomeIcon icon={faPlayCircle} {...props} />
const PlayIcon = (props) => <FontAwesomeIcon icon={faPlay} {...props} />
const PlugIcon = (props) => <FontAwesomeIcon icon={faPlug} {...props} />
const PlusIcon = (props) => <FontAwesomeIcon icon={faPlus} {...props} />
const PrintIcon = (props) => <FontAwesomeIcon icon={faPrint} {...props} />
const QuestionIcon = (props) => <FontAwesomeIcon icon={faQuestion} {...props} />
const RadioIcon = (props) => <FontAwesomeIcon icon={faRadio} {...props} />
const RepeatIcon = (props) => <FontAwesomeIcon icon={faRepeat} {...props} />
const RetroTvIcon = (props) => <FontAwesomeIcon icon={faTvRetro} {...props} />
const RoadIcon = (props) => <FontAwesomeIcon icon={faRoad} {...props} />
const RocketLaunchIcon = (props) => <FontAwesomeIcon icon={faRocketLaunch} {...props} />
const RotaryPhoneIcon = (props) => <FontAwesomeIcon icon={faPhoneRotary} {...props} />
const RunningIcon = (props) => <FontAwesomeIcon icon={faRunning} {...props} />
const SaveIcon = (props) => <FontAwesomeIcon icon={faSave} {...props} />
const SchoolIcon = (props) => <FontAwesomeIcon icon={faSchool} {...props} />
const ScrollOldIcon = (props) => <FontAwesomeIcon icon={faScrollOld} {...props} />
const ShareIcon = (props) => <FontAwesomeIcon icon={faShare} {...props} />
const ShieldCheckIcon = (props) => <FontAwesomeIcon icon={faShieldCheck} {...props} />
const ShieldHeartIcon = (props) => <FontAwesomeIcon icon={faShieldHeart} {...props} />
const ShieldIcon = (props) => <FontAwesomeIcon icon={faShield} {...props} />
const ShoePrintsIcon = (props) => <FontAwesomeIcon icon={faShoePrints} {...props} />
const SirenOnIcon = (props) => <FontAwesomeIcon icon={faSirenOn} {...props} />
const SlashIcon = (props) => <FontAwesomeIcon icon={faSlash} {...props} />
const SmileIcon = (props) => <FontAwesomeIcon icon={faSmile} {...props} />
const SmileWinkIcon = (props) => <FontAwesomeIcon icon={faSmileWink} {...props} />
const SnoozeIcon = (props) => <FontAwesomeIcon icon={faSnooze} {...props} />
const SquareIcon = (props) => <FontAwesomeIcon icon={faSquare} {...props} />
const SpinnerIcon = (props) => <FontAwesomeIcon icon={faSpinner} {...props} />
const SquareCheckIcon = (props) => <FontAwesomeIcon icon={faSquareCheck} {...props} />
const SeedlingIcon = (props) => <FontAwesomeIcon icon={faSeedling} {...props} />
const SpaIcon = (props) => <FontAwesomeIcon icon={faSpa} {...props} />
const SparklesIcon = (props) => <FontAwesomeIcon icon={faSparkles} {...props} />
const SpinnerThirdDuotoneIcon = (props) => <FontAwesomeIcon icon={faSpinnerThirdDuotone} {...props} />
const StarIcon = (props) => <FontAwesomeIcon icon={faStar} {...props} />
const StarsIcon = (props) => <FontAwesomeIcon icon={faStars} {...props} />
const StarShootingIcon = (props) => <FontAwesomeIcon icon={faStarShooting} {...props} />
const StopIcon = (props) => <FontAwesomeIcon icon={faStop} {...props} />
const SunriseIcon = (props) => <FontAwesomeIcon icon={faSunrise} {...props} />
const TabletScreenButtonIcon = (props) => <FontAwesomeIcon icon={faTabletScreenButton} {...props} />
const TemperatureThreeQuartersIcon = (props) => <FontAwesomeIcon icon={faTemperatureThreeQuarters} {...props} />
const ToolboxIcon = (props) => <FontAwesomeIcon icon={faToolbox} {...props} />
const ThumbsDownIcon = (props) => <FontAwesomeIcon icon={faThumbsDown} {...props} />
const ThumbsUpIcon = (props) => <FontAwesomeIcon icon={faThumbsUp} {...props} />
const TimesIcon = (props) => <FontAwesomeIcon icon={faTimes} {...props} />
const ToiletPaperIcon = (props) => <FontAwesomeIcon icon={faToiletPaperAlt} {...props} />
const TrainIcon = (props) => <FontAwesomeIcon icon={faTrain} {...props} />
const TrashIcon = (props) => <FontAwesomeIcon icon={faTrashXmark} {...props} />
const TrashCanIcon = (props) => <FontAwesomeIcon icon={faTrashCan} {...props} />
const TrafficCone = (props) => <FontAwesomeIcon icon={faTrafficCone} {...props} />
const TrafficLightStop = (props) => <FontAwesomeIcon icon={faTrafficLightStop} {...props} />
const TrafficLightSlow = (props) => <FontAwesomeIcon icon={faTrafficLightSlow} {...props} />
const TrafficLightGo = (props) => <FontAwesomeIcon icon={faTrafficLightGo} {...props} />
const TreeIcon = (props) => <FontAwesomeIcon icon={faTree} {...props} />
const TriangleExclamationIcon = (props) => <FontAwesomeIcon icon={faTriangleExclamation} {...props} />
const TriangleIcon = (props) => <FontAwesomeIcon icon={faTriangle} {...props} />
const TrophyIcon = (props) => <FontAwesomeIcon icon={faTrophy} {...props} />
const TruckClockIcon = (props) => <FontAwesomeIcon icon={faTruckClock} {...props} />
const UfoIcon = (props) => <FontAwesomeIcon icon={faUfo} {...props} />
const UnlockIcon = (props) => <FontAwesomeIcon icon={faUnlock} {...props} />
const UserCircleIcon = (props) => <FontAwesomeIcon icon={faUserCircle} {...props} />
const UserFriendsIcon = (props) => <FontAwesomeIcon icon={faUserFriends} {...props} />
const UserIcon = (props) => <FontAwesomeIcon icon={faUser} {...props} />
const UserSecretIcon = (props) => <FontAwesomeIcon icon={faUserSecret} {...props} />
const UserShieldIcon = (props) => <FontAwesomeIcon icon={faUserShield} {...props} />
const UsersCogIcon = (props) => <FontAwesomeIcon icon={faUsersCog} {...props} />
const UsersIcon = (props) => <FontAwesomeIcon icon={faUsers} {...props} />
const VideoIcon = (props) => <FontAwesomeIcon icon={faVideo} {...props} />
const VolumeIcon = (props) => <FontAwesomeIcon icon={faVolume} {...props} />
const VolumeHighIcon = (props) => <FontAwesomeIcon icon={faVolumeHigh} {...props} />
const VolumeLowIcon = (props) => <FontAwesomeIcon icon={faVolumeLow} {...props} />
const VolumeSlashIcon = (props) => <FontAwesomeIcon icon={faVolumeSlash} {...props} />
const WandMagicSparklesIcon = (props) => <FontAwesomeIcon icon={faWandMagicSparkles} {...props} />
const WhistleIcon = (props) => <FontAwesomeIcon icon={faWhistle} {...props} />
const WindowFrameIcon = (props) => <FontAwesomeIcon icon={faWindowFrame} {...props} />
const WizardHatIcon = (props) => <FontAwesomeIcon icon={faHatWizard} {...props} />
const XmarkIcon = (props) => <FontAwesomeIcon icon={faXmark} {...props} />
const CrownIcon = (props) => <FontAwesomeIcon icon={faCrown} {...props} />

export {
  AngleDownIcon,
  AngleLeftIcon,
  AngleRightIcon,
  AngleUpIcon,
  AngryIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowsRepeatIcon,
  ArrowUpRightFromSquareIcon,
  AsteriskIcon,
  AwardIcon,
  BallPileIcon,
  BarsIcon,
  BlenderIcon,
  BlockQuestionIcon,
  BoldIcon,
  BoltIcon,
  BookIcon,
  BookOpenIcon,
  BoxTissueIcon,
  BowlingPinsIcon,
  BrainIcon,
  BriefcaseIcon,
  BrightnessIcon,
  BuildingIcon,
  BullseyeIcon,
  BullseyePointerIcon,
  CalendarIcon,
  CalendarSolidIcon,
  CalendarStarIcon,
  CameraIcon,
  CameraWebIcon,
  ChartLineIcon,
  ChartMixedIcon,
  ChartUserIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  CircleIcon,
  CircleCheckIcon,
  CloudIcon,
  CloudArrowUpIcon,
  CloudSunIcon,
  CircleChevronLeftIcon,
  CircleChevronRightIcon,
  CircleExclamationIcon,
  CircleHalfStrokeIcon,
  CircleInfoIcon,
  CirclePauseIcon,
  ClockIcon,
  CompassIcon,
  CookieBiteIcon,
  CogIcon,
  CommentAltIcon,
  CommentCheckIcon,
  CommentQuestionIcon,
  CommentsIcon,
  CopyIcon,
  DashIcon,
  DesktopIcon,
  DiagramProjectIcon,
  DoNotEnterIcon,
  DogIcon,
  DoorClosedIcon,
  DoorOpenIcon,
  DownloadIcon,
  DropletIcon,
  DumbbellIcon,
  EarIcon,
  EditIcon,
  EnvelopeOpenIcon,
  ElephantIcon,
  EnvelopeOpenTextIcon,
  EqualsIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  EyeLowVisionIcon,
  EyeSlashIcon,
  FaceAngryIcon,
  FaceConfoundedIcon,
  FaceFearfulIcon,
  FaceFrownIcon,
  FaceGrimaceIcon,
  FaceGrinHeartsIcon,
  FaceGrinStarsIcon,
  FaceHandYawnIcon,
  FaceLaughBeamIcon,
  FaceMehIcon,
  FaceMehBlankIcon,
  FaceScreamIcon,
  FaceSmileIcon,
  FaceSmilingHandsIcon,
  FaceSleepingIcon,
  FaceSpiralEyesIcon,
  FaceSunglassesIcon,
  FaceThinkingIcon,
  FaceUnamusedIcon,
  FaceZipperIcon,
  FamilyIcon,
  FileIcon,
  FingerprintIcon,
  FireIcon,
  FireAltIcon,
  FireFlameSimpleIcon,
  FishFinsIcon,
  FlagIcon,
  ForkKnifeIcon,
  FrownIcon,
  FrownSlightIcon,
  GamepadModernIcon,
  GavelIcon,
  GiftIcon,
  GlassIcon,
  GolfBallTeeIcon,
  GrinStarsIcon,
  HandHoldingHandIcon,
  HandHoldingHeartIcon,
  HandPointRibbonIcon,
  HandPointUpIcon,
  HandIcon,
  HandsIcon,
  HandsClappingIcon,
  HandshakeIcon,
  HandsHoldingCircleIcon,
  HandsHoldingHeartIcon,
  HandWaveIcon,
  HeadSideIcon,
  HeadSideHeadphonesIcon,
  HeadphonesIcon,
  HeartCircleCheckIcon,
  HeartIcon,
  HeartPulseIcon,
  HomeIcon,
  HourglassStartIcon,
  HouseIcon,
  IceCreamIcon,
  IconsIcon,
  ImageIcon,
  InfoCircleIcon,
  InfinityIcon,
  ItalicIcon,
  LaptopMobileIcon,
  LightbulbIcon,
  LightbulbOnIcon,
  LinkIcon,
  LinkSlashIcon,
  ListIcon,
  ListOlIcon,
  ListCheckIcon,
  LockIcon,
  LockKeyholeIcon,
  LungsIcon,
  MagnifyingGlassIcon,
  MaskIcon,
  MehIcon,
  MessagesIcon,
  MessagesQuestionIcon,
  MicrophoneLinesIcon,
  MinusIcon,
  MobileIcon,
  MountainsIcon,
  MugIcon,
  MugTeaIcon,
  MusicIcon,
  MegaphoneIcon,
  OfficeChairIcon,
  PaperPlaneIcon,
  PaperPlaneTopIcon,
  PartyHornIcon,
  PersonSkiJumpingIcon,
  PauseIcon,
  PenAltIcon,
  PencilIcon,
  PenToSquareIcon,
  PeopleGroupIcon,
  PersonRaysIcon,
  PlaneIcon,
  PlayCircleIcon,
  PlayIcon,
  PlugIcon,
  PlusIcon,
  PrintIcon,
  QuestionIcon,
  RadioIcon,
  RepeatIcon,
  RetroTvIcon,
  RoadIcon,
  RocketLaunchIcon,
  RotaryPhoneIcon,
  RunningIcon,
  SaveIcon,
  SchoolIcon,
  ScrollOldIcon,
  ShareIcon,
  ShieldCheckIcon,
  ShieldHeartIcon,
  ShieldIcon,
  ShoePrintsIcon,
  SirenOnIcon,
  SlashIcon,
  SmileIcon,
  SmileWinkIcon,
  SnoozeIcon,
  SquareIcon,
  SpinnerIcon,
  SquareCheckIcon,
  SpaIcon,
  SparklesIcon,
  SpinnerThirdDuotoneIcon,
  StarIcon,
  StarsIcon,
  StarShootingIcon,
  StopIcon,
  SunriseIcon,
  SeedlingIcon,
  TabletScreenButtonIcon,
  TemperatureThreeQuartersIcon,
  ToolboxIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TimesIcon,
  ToiletPaperIcon,
  TrainIcon,
  TrashIcon,
  TrashCanIcon,
  TrafficCone,
  TrafficLightStop,
  TrafficLightSlow,
  TrafficLightGo,
  TreeIcon,
  TriangleExclamationIcon,
  TriangleIcon,
  TrophyIcon,
  TruckClockIcon,
  UfoIcon,
  UnlockIcon,
  UserCircleIcon,
  UserFriendsIcon,
  UserIcon,
  UserSecretIcon,
  UserShieldIcon,
  UsersCogIcon,
  UsersIcon,
  VideoIcon,
  VolumeIcon,
  VolumeHighIcon,
  VolumeLowIcon,
  VolumeSlashIcon,
  WandMagicSparklesIcon,
  WhistleIcon,
  WindowFrameIcon,
  WizardHatIcon,
  XmarkIcon,
  CrownIcon,
}

export { default as MoreMerrierIcon } from "./MoreMerrierIcon"
