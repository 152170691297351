import { useKitSession } from "../KitSessionContext"

import { useTeamLevelExerciseInstance } from "domains/Exercise/resource"
import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const TeamExerciseParticipantSelectFieldShortName = ({ identifier, noOneSelectedText }) => {
  const { kitInstance, team } = useKitSession()
  const { data: participants } = useKitParticipants({ kitInstance })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { data: teamExerciseInstance } = useTeamLevelExerciseInstance({
    teamId: team.id,
    kitInstanceId: kitInstance?.id,
    slug: kitInstance.kit.exercise.slug,
    refetchInterval: 10000,
    sessionRealtimeUpdates,
  })

  if (!participants || !teamExerciseInstance) {
    return <Loading inline />
  }

  const selectedUserId = getExerciseAnswer(teamExerciseInstance, identifier)
  const selectedUser = participants.find(({ id }) => id === selectedUserId)
  const selectedUserDisplayValue = selectedUser ? selectedUser.short_name : noOneSelectedText

  return selectedUserDisplayValue
}

export default TeamExerciseParticipantSelectFieldShortName
