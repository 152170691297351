import cn from "classnames"
import Sticky from "react-stickynode"
import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import GroupTimer from "./GroupTimer"

import { useUser } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Timer from "ui/Timer"
import TimerWithRef from "ui/TimerWithRef"
import View from "ui/View"
import { KitType } from "utils/kit"
import { useHasTeamFeature } from "utils/team"

const StepTitle = ({
  children,
  breadcrumbs,
  sessionStep,
  minutes,
  startSeconds,
  className,
  timerRef,
  autoStartTimer = false,
  showTimerAll = false,
  showTimerNone = false,
  showTimerLead = false,
  showTimerControls = true,
  isTeamLead,
  ...props
}) => {
  const { kitInstance, team } = useKitSession()
  const { data: user } = useUser({ userId: "me" })
  const { enabled: realtimeTimerEnabled } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ACTIVITY_TIMER)
  const kitType = kitInstance.kit.type
  const isMiniKit = kitType === KitType.MINI

  if (!user) {
    return null
  }

  // Set parameters based on data from sessionStep and props:
  // (if prop provided, that value will override sessionStep data)
  minutes = minutes || sessionStep.minutes
  startSeconds = startSeconds || sessionStep.startSeconds || minutes * 60
  autoStartTimer = !!(autoStartTimer || sessionStep.auto_start_timer)
  showTimerControls = !!(showTimerControls || sessionStep.show_timer_controls)
  // showTimerXYZ prop values passed directly to StepTitle component should always take
  // precedent over sessionStep.show_timer_xyz values. This allows hiding the top-level
  // title timer and defining different behavior for a timer within the step.
  //   showTimerAll  - show timer to all users in all circumstances
  //   showTimerNone - if timer not part of a group, show timer to no users
  //   showTimerLead - if timer not part of a group, show timer to lead user only
  const showTimerAllProp = showTimerAll
  const showTimerNoneProp = showTimerNone
  const showTimerLeadProp = showTimerLead
  showTimerAll = !showTimerNoneProp && !!(showTimerAllProp || sessionStep.show_timer_all)
  showTimerNone = !showTimerAllProp && !!(showTimerNoneProp || sessionStep.show_timer_none)
  showTimerLead = !showTimerNoneProp && !!(showTimerLeadProp || sessionStep.show_timer_lead)

  const timerGroup = sessionStep.timer_group
  const showStepTime = !!sessionStep.show_step_time // default: false
  // showStepTime: specifies whether to show time of the entire group, or just current step

  // if timer within group, we always show to all:
  if (timerGroup) {
    showTimerAll = true
  }

  const showTimer = (!showTimerNone && showTimerAll) || (showTimerLead && user.id === team.team_lead_id)
  const validTimer = timerGroup || (minutes && startSeconds)

  return (
    <>
      {showTimer && validTimer ? (
        timerGroup && (!showStepTime || !!realtimeTimerEnabled) ? (
          <StickyTimerContainer
            breadcrumbs={breadcrumbs}
            timer={
              <GroupTimer
                sessionStep={sessionStep}
                showTimerControls={showTimerControls}
                showStepTime={showStepTime}
                isTeamLead={isTeamLead}
              />
            }
          >
            {children}
            {!!breadcrumbs && <div className="full-width mt-medium">{breadcrumbs}</div>}
          </StickyTimerContainer>
        ) : (
          <TimerWithRef
            ref={timerRef}
            TimerComponent={StepTitleWithTimer}
            startSeconds={startSeconds}
            autoStartTimer={autoStartTimer}
            timerProps={{
              showTimerControls,
              isMiniKit,
              children: (
                <>
                  {children}
                  {!!breadcrumbs && <div className="full-width mt-medium">{breadcrumbs}</div>}
                </>
              ),
              breadcrumbs,
            }}
          />
        )
      ) : (
        <View
          $alignItems="flex-start"
          $flexWrap="wrap"
          className={cn(className, "mb-small pt-xs")}
          {...props}
          data-testid="session-step-title"
        >
          {children}
          {!!breadcrumbs && <div className="full-width mt-medium">{breadcrumbs}</div>}
        </View>
      )}
    </>
  )
}

const StepTitleWithTimer = ({
  children,
  breadcrumbs,
  durationSeconds,
  isRunning,
  onPlay,
  onPause,
  onReset,
  showTimerControls,
  isMiniKit,
  className,
}) => {
  const additionalTimerProps = isMiniKit ? { invertColors: true, warningColorDuration: 15, dangerColorDuration: 1 } : {}
  return (
    <StickyTimerContainer
      breadcrumbs={breadcrumbs}
      timer={
        <Timer
          durationSeconds={durationSeconds}
          isRunning={isRunning}
          onPlay={onPlay}
          onPause={onPause}
          onReset={onReset}
          showTimerControls={showTimerControls}
          {...additionalTimerProps}
        />
      }
      className={className}
    >
      {children}
    </StickyTimerContainer>
  )
}

const StickyTimerContainer = styled(function StickyTimerContainer({ children, breadcrumbs, timer, className }) {
  return (
    <Sticky innerZ={99} className={cn(className, "full-width", { "has-breadcrumbs": breadcrumbs })}>
      <View $alignItems="center" className="flex-container wrap-on-tablet hide-in-print stay-top fg py-xs mb-xxs">
        <div className="timer-text hide-on-tablet-or-desktop">
          <h3 className="text-gray-9">Timer</h3>
        </div>
        <div className="title-container" data-testid="session-step-title">
          {children}
        </div>
        <View className="timer-container ml-auto" $width="auto">
          {timer}
        </View>
      </View>
    </Sticky>
  )
})`
  &.active .stay-top {
    box-shadow: 0 4px 4px -4px rgb(35 35 35 / 20%);
  }

  // "@media not all and": This is the best known way to invert the max-width media
  // rule below, so we can apply CSS only when app is NOT on mobile.
  // See: https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries#inverting_a_querys_meaning
  @media not all and (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    &.has-breadcrumbs {
      .flex-container {
        align-items: flex-end;
      }

      .timer-container {
        margin-bottom: 12px;

        // The following two properties allow the title text
        // to not wrap when the timer shows "Time's up!":
        max-width: 0;
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    &.has-breadcrumbs:not(.active) .timer-container {
      margin-top: -3rem; // align timer with breadcrumbs (progress bar)
    }

    &.active .title-container {
      display: none !important;
    }

    &:not(.active) .timer-text {
      display: none !important;
    }

    .stay-top {
      width: calc(100% + 24px * 2);
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
    .stay-top {
      width: calc(100% + 48px * 2);
      margin-left: -48px;
      margin-right: -48px;
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    &:not(.active) .wrap-on-tablet {
      flex-wrap: wrap;

      > div {
        margin-bottom: var(--spacing-1);
      }
    }
  }
`

export default StepTitle
