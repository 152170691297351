import { useQueryClient } from "@tanstack/react-query"
import { Formik } from "formik"
import { pick } from "lodash-es"
import { useNavigate, useParams } from "react-router-dom"
import { styled } from "styled-components"

import { checkUserEmail, useRegister, usePromo } from "../resource"

import { useBillingData } from "./BillingDataContext"
import { CeoImage, NonCEOImage } from "./CeoImage"
import NumTeamsDropdown from "./NumTeamsDropdown"
import { TeamLeadRegisterForm } from "./RegisterForm"
import TermsAndConditions, { SHRMTermsAndConditions } from "./TermsAndConditions"

import LoginForm from "domains/Authentication/forms/LoginForm"
import handleErrors from "forms/handleErrors"
import Yup, { profileSchemas, userSchemas } from "forms/yup"
import { SquareCheckIcon } from "icons/FontAwesomeIcons"
import StripesBandDesktopIcon from "icons/StripesBandDesktopIcon"
import { getOwnedAccount, convertAccountToPromo } from "resources/billing"
import Button from "ui/Button"
import useEffectOnComponentUnmount from "ui/hooks/useEffectOnComponentUnmount"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import { useModal } from "ui/ModalContext"
import PageTitle from "ui/PageTitle"
import TextContainer from "ui/TextContainer"
import View from "ui/View"
import { getTimeZone } from "utils/date"
import { buildUrl } from "utils/string"

const RegisterForPurchase = ({ className, promoCode = null }) => {
  const queryClient = useQueryClient()
  const { billingData } = useBillingData()
  const { freeTrial, gratitudePromo } = billingData
  const { data: promo, isInitialLoading: promoIsInitialLoading } = usePromo(promoCode)
  const { mutateAsync: register } = useRegister()
  const navigate = useNavigate()
  const { subscription, quantity } = useParams()
  const urlQueryParams = useQueryParams()
  const { first_name, last_name, email } = urlQueryParams
  const { setModal } = useModal()

  // Close login modal automatically when we navigate away from signup page:
  useEffectOnComponentUnmount(() => setModal(null))

  let showMinimalRegisterForm = true
  let initialValues = {
    email: email || "",
    first_name: first_name || "",
    last_name: last_name || "",
    password: "",
  }
  const baseUserSchema = pick(userSchemas, Object.keys(initialValues))
  let registerSchema = Yup.object().shape(baseUserSchema)

  if (promoIsInitialLoading) {
    return <Loading />
  }
  const isSHRM24Promo = !!promo?.registration_info?.is_shrm_24_promo

  // If promo, add optional pronouns and job_function fields to form:
  if (isSHRM24Promo) {
    initialValues = {
      ...initialValues,
      profile: {
        org_name: "",
        org_size: "",
        job_function: "",
        job_position: "",
      },
    }
    registerSchema = Yup.object().shape({
      ...baseUserSchema,
      profile: Yup.object().shape({
        org_size: Yup.string().required("This field is required.").label("Company size"),
        job_position: Yup.string().required("This field is required.").label("Job position"),
        ...pick(profileSchemas, ["org_name", "job_function", "phone_number"]),
      }),
    })
  } else if (promo) {
    showMinimalRegisterForm = false
    initialValues = {
      ...initialValues,
      profile: {
        pronouns: "",
        job_function: "",
      },
    }
    registerSchema = Yup.object().shape({
      ...baseUserSchema,
      profile: Yup.object().shape({
        pronouns: Yup.string().label("Pronouns").optional(),
        job_function: Yup.string().label("Job function").optional(),
      }),
    })
  }

  function openLoginModal({ initialEmail = null, children = null } = {}) {
    const redirectUrl = !gratitudePromo
      ? null
      : buildUrl(["get-started", "sub", subscription, quantity], {
          urlQueryParams: { gratitude_promo: 1 },
        })
    setModal({
      title: (
        <div className="text-white">
          <SquareCheckIcon className="mr-xxs" /> Log in to Rising Team
        </div>
      ),
      content: (
        <>
          {children}
          <LoginForm className="mt-xxxl" initialEmail={initialEmail} redirectUrl={redirectUrl} showCodeLink={false} />
        </>
      ),
      modalWithColorTitleBar: true,
      modalWithColorBorder: true,
    })
  }

  const onSubmit = handleErrors(async (values) => {
    const { email } = values

    const userExists = await checkUserEmail(email)
    if (userExists) {
      openLoginModal({
        initialEmail: email,
        children: (
          <div className="mt-xxl">
            <h4>It looks like you already have an account.</h4>
            {!!gratitudePromo ? (
              <h4>Please log in to receive your free kit.</h4>
            ) : (
              <h4>
                Please log in or{" "}
                <a href="mailto:hello@risingteam.com" target="_blank" rel="noopener noreferrer">
                  contact us
                </a>{" "}
                for support.
              </h4>
            )}
          </div>
        ),
      })
    } else {
      await register({
        ...values,
        time_zone: getTimeZone(),
        self_sign_up: true,
      })
      if (promo) {
        // For promos, set up account and then navigate to specified url:
        const account = await getOwnedAccount()
        await convertAccountToPromo(account.id, promo.code, queryClient)
        navigate(buildUrl(promo.redirect_url_parts))
      } else {
        // In all other cases, navigate to onboarding profile form:
        navigate(
          buildUrl(["get-started", "create-account", "onboarding"], {
            urlQueryParams: { ...urlQueryParams, subscription, quantity },
          })
        )
      }
    }
  })

  let title = "Join Rising Team"
  if (promo) {
    title = promo?.registration_info?.title || "Register to access free kit"
  } else if (freeTrial) {
    title = "Try Rising Team for free!"
  }

  let subTitle = `
    Get ready to elevate your leadership and help your team connect and grow together
  `
  if (promo) {
    subTitle =
      promo?.registration_info?.subtitle ||
      `
      Set up your Rising Team account today. Instantly access a free kit for running a
      session with your team.
    `
  }

  let ceoText = null
  if (promo) {
    ceoText = `
      Hi, I’m Jen. After 25+ years leading teams at Google, Facebook, and Change.org,
      I created the team development tool I wish we had. Rising Team is for leaders
      like you to build more engaged, connected, and successful teams. Enjoy!
    `
  }

  let submitButtonText = null
  if (isSHRM24Promo) {
    submitButtonText = "Get started"
  } else if (promo) {
    submitButtonText = "Access kit"
  }

  let logo = null
  let logoAltText = null
  if (isSHRM24Promo) {
    logo = promo?.registration_info?.header_image ?? null
    logoAltText = promo?.registration_info?.logo_alt_text ?? null
  }

  return (
    <>
      <PageTitle>Let’s get your account set up</PageTitle>
      <View $flex={1} $flexDirection="column" className={className}>
        <View className="mb-medium">
          <TextContainer maxWidth={650} className="pr-large">
            {!!logo && (
              <img
                src={`https://static.risingteam.com/kit_assets/onboarding/${logo}`}
                alt={logoAltText ? logoAltText : "Onboarding logo"}
                width="100%"
              />
            )}
            <h1 className="text-gray-9 mt-xs mb-medium hide-on-mobile">{title}</h1>
            <h2 className="text-gray-9 mt-xs mb-medium show-on-mobile">{title}</h2>
            <p className="text-subtitle text-gray-8 mb-medium">{subTitle}</p>
            <div className="text-login-here text-gray-9">
              Already have an account?{" "}
              <Button className="link-blue" onClick={openLoginModal}>
                Log in instead
              </Button>
            </div>
          </TextContainer>
          <div className="right-column show-on-desktop">
            <StripesBandDesktopIcon className="stripes-band-icon-desktop" />
          </div>
        </View>

        {!!billingData.selectNumTeams && <NumTeamsDropdown className="hide-on-desktop num-teams-dropdown" />}
        <View $width="100%" $gap="var(--spacing-4)">
          <View $flexDirection="column" $width="100%">
            {!!billingData.selectNumTeams && <NumTeamsDropdown className="show-on-desktop num-teams-dropdown" />}
            <Formik
              initialValues={initialValues}
              validationSchema={registerSchema}
              validateOnBlur={false}
              onSubmit={onSubmit}
            >
              <TeamLeadRegisterForm
                invited={false}
                inputFieldSize="large"
                showMinimalRegisterForm={showMinimalRegisterForm}
                submitButtonText={submitButtonText}
                isSHRMInvite={!!isSHRM24Promo}
              />
            </Formik>
          </View>
          <View>
            {!!isSHRM24Promo ? (
              <NonCEOImage imageFilename={promo?.registration_info?.side_image} />
            ) : (
              <CeoImage text={ceoText} />
            )}
          </View>
        </View>
        {!!isSHRM24Promo ? (
          <SHRMTermsAndConditions />
        ) : (
          <TextContainer maxWidth={644}>
            <TermsAndConditions buttonText={submitButtonText ? `"${submitButtonText}"` : '"Let’s do this"'} />
          </TextContainer>
        )}
      </View>
    </>
  )
}

export default styled(RegisterForPurchase)`
  .text-subtitle {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .text-login-here {
    font-size: 1rem;
    line-height: 1.875rem;
  }

  form {
    width: 330px;
  }

  .num-teams-dropdown {
    width: 330px;
  }

  .right-column {
    width: 200px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .stripes-band-icon-desktop {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`
