import { zipObject } from "lodash-es"
import { darken, lighten, rgba } from "polished"
import { createGlobalStyle } from "styled-components"

import { ZIndex, ZIndexLayer, aboveZIndex, belowZIndex } from "ui/z-index"

// Our primary colors, which are used for multiple values in colors object
const gray9 = "#484848"
const gray8 = "#777777"
const risingOrange = "#FB6211"
const risingGreen = "#50C05C"
const risingBlue = "#3E6AE1"
const risingYellow = "#FFD257"
const errorRed = "#E82C2C"

const colors = {
  color: gray9,
  primary: risingOrange,
  secondary: risingBlue,
  success: risingGreen,
  brightSuccess: "#1CCA39",
  warning: risingYellow,
  danger: errorRed,
  foreground: "#FFF",
  background: "#FAFAFA",
  placeholder: gray8,
  subtitle: "#777777",
  gray1: "#FAFAFA",
  gray2: "#F8F8F8",
  gray3: "#F2F2F2",
  gray4: "#EEEEEE",
  gray5: "#DDDDDD",
  gray6: "#BDBDBD",
  gray7: "#919191",
  gray8,
  gray9,
  gray10: "#232323",
  gray30: "rgba(81, 81, 98, 1)",
  overlay: "rgba(38, 38, 38, 0.8)",
  editable: risingYellow,
  radialGradient: "radial-gradient(circle at 50% 50%, #ffd257, #fb6211)",
  linearGradient: "linear-gradient(240deg, #ffd257, #fb6211)",
  linearGradientConnect: "linear-gradient(257deg, #FFF0C6 0%, #FFF 37.43%)",
  linearGradientPlay: "linear-gradient(257deg, #DFFEE2 0%, #FFF 37.43%)",
  linearGradientUnderstand: "linear-gradient(257deg, #FFE4D6 0%, #FFF 37.43%)",
  linearGradientUnavailable: "linear-gradient(257deg, #EEE 0%, #FFF 37.43%)",
  risingOrange,
  risingOrangeMedium: "#EE5504",
  risingOrangeDark: "#D54C04",
  orange2: "#FE7A00",
  orange3: "#FE901B",
  orange4: "#FF9F0A",
  orangeTint: "#FFFBF7",
  errorRed,
  risingGreen,
  green2: "#5CCF68",
  green3: "#48AC52",
  green4: "#409949",
  greenTint: "#F5FAF4",
  yellowGreen: "#92CA4B",
  yellowTint: "#FFFAED",
  yellowOffTint: "#FFDB79",
  risingYellow,
  yellow2: "#FAC126",
  yellow3: "rgba(255, 210, 87, 0.07)",
  risingBlue,
  blue2: "#0A84FF",
  blueTint: "#0A84FF0F",
  chartGreen: "#9FCA4B",
  chartMagenta1: "#C955C6",
  chartMagenta2: "#BD4BC5",
  chartPurple1: "#8B5DD8",
  chartPurple2: "#8984D6",
  chartPurple3: "#7B61FF",
  chartBlue1: "#69C8DD",
  chartBlue2: "#00A2E0",
  chartOrange: "#F79025",
  white: "#FFFFFF",
  white2: "#FFFEFE",
  red8: "#FDEDED",
}

const theme = {
  ...colors,
  themeClassname: "theme-default",
  isWidescreen: false,
  hideHeader: false,
  borderColor: "#DDDDDD",
  borderLight: "#F2F2F2",
  borderDark: "#BDBDBD",
  hoverBackground: darken(0.1, colors.primary),
  activeBackground: darken(0.1, colors.primary),
  radius: "4px",
  normalFontFamily: "'Montserrat', -apple-system, sans-serif",
  normalFontSize: "0.9375rem", // ~15px
  normalLineHeight: "1.75rem",
  mobileMax: "479px",
  mobileLandscapeMin: "480px",
  mobileLandscapeMax: "767px",
  tabletMin: "768px",
  tabletMax: "1023px",
  desktopMin: "1024px",
  maxContent: "932px",
  maxScreen: "1024px",
  spacing0: "4px", // xxs
  spacing1: "8px", // xs
  spacing2: "12px", // small
  spacing3: "16px", // medium
  spacing4: "24px", // large
  spacing5: "32px", // xl
  spacing6: "40px", // xxl
  spacing7: "48px", // xxxl
  spacing8: "64px", // xxxxl
  textContainerDefaultMaxWidth: 700,
}

const generateStyleText = (baseClassName, styleName, values, { includeImportant = false } = {}) =>
  values
    .map(
      (val) => `
      .${baseClassName}${val} {
        ${styleName}: var(--${val});
      }
      ${includeImportant ? `.${baseClassName}${val}-important { ${styleName}: var(--${val}) !important; }` : ""}
    `
    )
    .join("")

const generateNumberedSuffix = (suffixMin, suffixMax, base) =>
  Array.from(Array(suffixMax + 1).keys())
    .slice(suffixMin)
    .map((i) => `${base}${i}`)

const spacingNames = ["xxs", "xs", "small", "medium", "large", "xl", "xxl", "xxxl", "xxxxl"]
const generateSpacingStyles = (baseClassName, styleNames) => {
  const makeStyle = (idx) => (name) => `${name}: var(--spacing-${idx});\n`
  const makeStyles = (idx) => styleNames.map(makeStyle(idx))
  const styles = spacingNames.map((_, idx) => makeStyles(idx).join(""))
  return (
    // Basic spacing styles: m-medium mt-xs, mr-xxl, etc.
    spacingNames
      .map(
        (name, idx) => `
        .${baseClassName}${name} {
          ${styles[idx]}
        }`
      )
      .join("") +
    // Negative spacing styles: neg-m-medium neg-mt-xs, neg-mr-xxl, etc.
    spacingNames
      .map((name, idx) => {
        // rewrite all values like "var(--spacing-N)" to "calc(-1 * var(--spacing-N))"
        let negatedStyle = styles[idx]
        negatedStyle = styles[idx].replaceAll("var(", "calc(-1 * var(")
        negatedStyle = negatedStyle.replaceAll(")", "))")
        return `
          .neg-${baseClassName}${name} {
            ${negatedStyle}
          }
        `
      })
      .join("")
  )
}

const zIndexLayerStyleData = Object.entries(ZIndexLayer).map(([layer, value]) => ({
  layer: layer.toLowerCase(),
  value,
  above: aboveZIndex(value),
  below: belowZIndex(value),
}))

const getThemeStyles = (theme) => `
  :root {
    --default: ${theme.color};
    --primary: ${theme.primary};
    --primary-hover: ${theme.hoverBackground};
    --primary-active: ${theme.activeBackground};
    --primary-opacity: ${rgba(theme.primary, 0.1)};
    --secondary: ${theme.secondary};
    --secondary-hover: ${darken(0.1, theme.secondary)};
    --secondary-opacity: ${rgba(theme.secondary, 0.1)};
    --success: ${theme.success};
    --success-hover: ${darken(0.1, theme.success)};
    --success-opacity: ${rgba(theme.success, 0.1)};
    --bright-success: ${theme.brightSuccess};
    --warning: ${theme.warning};
    --warning-hover: ${darken(0.1, theme.warning)};
    --warning-opacity: ${rgba(theme.warning, 0.1)};
    --warning-bg: ${lighten(0.4, theme.warning)};
    --subtitle: ${theme.subtitle};
    --danger: ${theme.danger};
    --danger-hover: ${darken(0.1, theme.danger)};
    --danger-opacity: ${rgba(theme.danger, 0.1)};
    --linear-gradient: ${theme.linearGradient};
    --radial-gradient: ${theme.radialGradient};
    --linear-gradient-connect: ${theme.linearGradientConnect};
    --linear-gradient-play: ${theme.linearGradientPlay};
    --linear-gradient-understand: ${theme.linearGradientUnderstand};
    --linear-gradient-unavailable: ${theme.linearGradientUnavailable};
    --border-color: ${theme.borderColor};
    --border-light: ${theme.borderLight};
    --border-dark: ${theme.borderDark};
    --border-color-hover: ${theme.borderColor};
    --fg: ${theme.foreground};
    --bg: ${theme.background};
    --placeholder: ${theme.placeholder};
    --border-radius: ${theme.radius};
    --border-radius-xxs: 2px;
    --border-radius-xs: 4px;
    --border-radius-small: 8px;
    --border-radius-medium: 12px;
    --border-radius-large: 20px;
    --border-radius-xl: 40px;
    --border-radius-xxl: 100px;
    --overlay: ${theme.overlay};
    --editable: ${theme.editable};
    --rising-orange: ${theme.risingOrange};
    --rising-orange-medium: ${theme.risingOrangeMedium};
    --rising-orange-dark: ${theme.risingOrangeDark};
    --orange-2: ${theme.orange2};
    --orange-2-background:  ${rgba(theme.orange2, 0.3)};
    --orange-3: ${theme.orange3};
    --orange-4: ${theme.orange4};
    --orange-4-background:  ${rgba(theme.orange4, 0.3)};
    --orange-4-tint: ${rgba(theme.orange4, 0.1)};
    --orange-tint: ${theme.orangeTint};
    --error-red: ${theme.errorRed};
    --rising-green: ${theme.risingGreen};
    --rising-green-background: ${rgba(theme.risingGreen, 0.3)};
    --green-2: ${theme.green2};
    --green-3: ${theme.green3};
    --green-4: ${theme.green4};
    --green-tint: ${theme.greenTint};
    --rising-yellow: ${theme.risingYellow};
    --rising-yellow-background:  ${rgba(theme.risingYellow, 0.3)};
    --yellow-2: ${theme.yellow2};
    --yellow-3: ${theme.yellow3};
    --yellow-green: ${theme.yellowGreen};
    --yellow-tint: ${theme.yellowTint};
    --yellow-off-tint: ${theme.yellowOffTint};
    --rising-blue: ${theme.risingBlue};
    --blue-2: ${theme.blue2};
    --blue-tint: ${theme.blueTint};
    --chart-green: ${theme.chartGreen};
    --chart-green-background: ${rgba(theme.chartGreen, 0.3)};
    --chart-magenta-1: ${theme.chartMagenta1};
    --chart-magenta-2: ${theme.chartMagenta2};
    --chart-purple-1: ${theme.chartPurple1};
    --chart-purple-2: ${theme.chartPurple2};
    --chart-purple-3: ${theme.chartPurple3};
    --chart-blue-1: ${theme.chartBlue1};
    --chart-blue-2: ${theme.chartBlue2};
    --chart-orange: ${theme.chartOrange};
    --red-8: ${theme.red8};
    --gray-1: ${theme.gray1};
    --gray-2: ${theme.gray2};
    --gray-3: ${theme.gray3};
    --gray-4: ${theme.gray4};
    --gray-5: ${theme.gray5};
    --gray-6: ${theme.gray6};
    --gray-7: ${theme.gray7};
    --gray-8: ${theme.gray8};
    --gray-9: ${theme.gray9};
    --gray-10: ${theme.gray10};
    --gray-30: ${theme.gray30};
    --white-2: ${theme.white2};
    --white: ${theme.white};

    --spacing-0: ${theme.spacing0};  // xxs
    --spacing-1: ${theme.spacing1};  // xs
    --spacing-2: ${theme.spacing2};  // small
    --spacing-3: ${theme.spacing3};  // medium
    --spacing-4: ${theme.spacing4};  // large
    --spacing-5: ${theme.spacing5};  // xl
    --spacing-6: ${theme.spacing6};  // xxl
    --spacing-7: ${theme.spacing7};  // xxxl
    --spacing-8: ${theme.spacing8};  // xxxxl

    --blur-1: 0px 0px 2px rgba(35, 35, 35, 0.15);
    --blur-2: 0px 0px 2px rgba(0, 0, 0, 0.2);
    --blur-4: 0px 0px 4px rgba(35, 35, 35, 0.15);
    --blur-blue-1: 0px 0px 2px rgba(10, 132, 255, 0.35);
    --lift-2: 0px 2px 2px rgba(35, 35, 35, 0.2);
    --lift-4: 0px 2px 4px rgba(35, 35, 35, 0.2);
    --lift-6: 0px 3px 6px rgba(35, 35, 35, 0.3);
    --lift-8: 0px 4px 8px rgba(35, 35, 35, 0.2);
    --blue-lift: 1px 1px 2px rgba(10, 132, 255, 0.25);
    --progress-bar-gradient: linear-gradient(258.26deg, #fb6211 28%, #f97830 62.68%, #f8d313 94.29%);
    --toastify-color-progress-light: var(--progress-bar-gradient);

    --content-max: ${theme.maxContent};
    --screen-max: ${theme.maxScreen};

    --z-above: ${ZIndex.Above};
    --z-below: ${ZIndex.Below};
    --z-max: ${ZIndex.Max};

    ${zIndexLayerStyleData
      .map(
        ({ layer, value, above, below }) => `
      --z-${layer}: ${value};
      --z-above-${layer}: ${above};
      --z-below-${layer}: ${below};
    `
      )
      .join("\n")}
  }

  ${zIndexLayerStyleData
    .map(
      ({ layer }) => `
    .z-${layer} { z-index: var(--z-${layer}); }
    .z-above-${layer} { z-index: var(--z-above-${layer}); }
    .z-below-${layer} { z-index: var(--z-below-${layer}); }
  `
    )
    .join("\n")}

  html,
  body,
  button,
  input,
  textarea,
  select {
    font-family: ${theme.normalFontFamily};
    font-size: 16px;
  }

  html, body, #root {
    color: var(--default);
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100vw;
    -webkit-font-smoothing: antialiased;
  }

  .ReactModal__Body--open {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
  }

  body {
    overflow-x: hidden;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }

  #root {
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--bg);
  }

  .rtkitview {
    position: relative;
    background-color: var(--fg);

    .main-container {
      padding: 24px;
    }
  }

  @media (max-width: ${theme.mobileMax}) {
    .screen-max-width,
    .content-max-width {
      max-width: 415px;
    }
    .logo-container {
      .logo-desktop {
        display: none;
      }

      .logo {
        width: 48px;
        height: 48px;
      }

      .logo-text-container {
        margin-left: var(--spacing-2);
      }


      &.column {
        .logo {
          width: 48px;
          height: 48px;
        }

        .name {
          font-size: 28px;
          margin-top: 4px;
          margin-left: 0px;
        }
      }
    }
  }

  @media (min-width: ${theme.mobileLandscapeMin}) {
    .screen-max-width,
    .content-max-width {
      max-width: 626px;
      margin: var(--spacing-3) auto;
    }
    .rtkitview {
      box-shadow: var(--blur-4);
      border-radius: 4px;
      margin: 0 var(--spacing-3);

      .main-container {
        padding: 48px 48px;
      }
    }

    .logo-container {
      .logo-mobile {
        display: none;
      }
      .logo {
        width: 60px;
        height: 60px;
      }
      .logo-text {
        height: 30px;
        width: 116px;
      }

      .name {
        font-size: 28px;
        margin-top: 4px;
        margin-left: 0px;
      }
    }
  }

  @media (min-width: ${theme.tabletMin}) {
    .screen-max-width {
      max-width: 859px;
    }
    .rtkitview .maincontainer {
      padding: 40px 80px 80px 80px;
    }
  }

  @media (min-width: ${theme.desktopMin}) {
    .content-max-width {
      max-width: var(--content-max);
    }
    .screen-max-width {
      max-width: var(--screen-max);
    }
  }

  @media (min-width: ${theme.desktopMin}) {
    .content-with-facilitator-tip-max-width {
      max-width: 70%;
    }
  }

  // .fade, .hidden, .non-interactive-and-faded classes are meant to be used together
  // once an element has .fadeable, toggling .hidden will smoothly fade it in/out
  // (or toggling .non-interactive-and-faded)
  .fadeable {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .hidden {
    pointer-events: none;
    opacity: 0;
  }
  .non-interactive {
    pointer-events: none;
  }
  .non-interactive-and-faded {
    pointer-events: none;
    opacity: 0.4
  }

  .inline {
    display: inline;
  }
  .inline-block {
    display: inline-block;
  }
  .block {
    display: block !important;
  }

  .line-break-anywhere {
    line-break: anywhere;
  }

  .react-datepicker-wrapper {
    // otherwise svg inside have a bigger height
    display: flex !important;
  }
  .react-datepicker-popper {
    z-index: var(--z-menu);
  }

  ::placeholder {
    color: var(--placeholder);
    font-weight: 400;
    text-transform: none;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: var(--rising-blue);
    text-decoration: none;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      cursor: default;
    }

    &:hover, &:focus-visible {
      text-decoration: underline;
      &.no-underline {
        text-decoration: none
      }
    }
  }

  .editable {
    border: 1px solid var(--editable);
  }

  .align-self-center {
    align-self: center;
  }
  .align-self-stretch {
    align-self: stretch;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-right {
    justify-content: right;
  }

  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-strikethrough {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    text-decoration-thickness: 1px;
  }

  ${
    /*
      .text-gray-1 {
        color: var(--gray-1);
      }
    */
    generateStyleText(
      "text-",
      "color",
      [
        "default",
        "primary",
        "secondary",
        "success",
        "bright-success",
        "rising-orange",
        "rising-orange-medium",
        "rising-orange-dark",
        "orange-2",
        "orange-3",
        "orange-4",
        "error-red",
        "rising-green",
        "green-2",
        "green-3",
        "green-4",
        "yellow-green",
        "rising-yellow",
        "yellow-2",
        "rising-yellow",
        "rising-blue",
        "blue-2",
        "subtitle",
        "danger",
        "warning",
        "white",
        ...generateNumberedSuffix(1, 10, "gray-"),
        "fg",
      ],
      { includeImportant: true }
    )
  }

  .text-semi-bold {
    font-weight: 600;
  }

  .text-thin {
    font-weight: 400;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-italic {
    font-style: italic;
  }

  .text-danger {
    color: var(--error-red);
  }

  .text-xs {
    font-size: 0.668rem; // ~11px
    line-height: 1.25rem;
  }

  .text-small {
    font-size: 0.875rem; // ~14px
    line-height: 1.5rem;
  }

  .text-normal {
    // These need to be specified in variables instead of nesting in theme classes like .theme-widescreen
    // because the text-normal class is used in index.html before we add the theme class
    font-size: ${theme.normalFontSize};
    line-height: ${theme.normalLineHeight};
    font-weight: normal;
  }

  .text-medium {
    font-size: 1.625rem; // ~26px
    line-height: 2rem;
  }

  .text-large {
    font-size: 2rem; // ~32px
    line-height: 2.75rem;
  }

  .text-huge {
    font-size: 3rem; // ~48px
    line-height: 4.3rem;
  }

  @media (max-width: ${theme.mobileLandscapeMax}) {
    .text-huge {
      font-size: 2rem; // ~32px
      line-height: 2.75rem;
    }
  }

  .text-field-label {
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    font-weight: 600;
  }

  .text-field-response {
    font-size: 0.9375rem; // ~15px
    line-height: 1.375rem;
    font-weight: 400;
    color: var(--gray-9);
  }

  .text-utility {
    font-size: 0.9375rem; // ~15px
    line-height: 1.313rem; // ~21px
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-wrap {
    white-space: normal;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .text-wrap-word {
    white-space: normal;
    word-wrap: break-word;
  }

  .text-break-word {
    word-break: break-word;
  }

  .text-normal-min-height {
    min-height: 44px;
  }

  .fit-content {
    width: fit-content;
    height: fit-content;
  }

  .object-fit-scale-down {
    object-fit: scale-down;
  }

  .full-width {
    width: 100%;
  }
  .half-width {
    width: 50%;
  }
  .full-height {
    height: 100%;
  }

  .text-no-select{
    user-select: none;
  }

  .text-align-right {
    text-align: right
  }
  .text-align-left {
    text-align: left
  }

  .cursor-default {
    cursor: default;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-text {
    cursor: text;
  }

  .interactive-shadow {
    box-shadow: var(--blur-2);

    &:hover {
      box-shadow: var(--lift-4);
    }
  }

  @media (max-width: ${theme.mobileMax}) {
    .hide-on-mobile {
      display: none !important;
    }
  }
  @media (min-width: ${theme.mobileLandscapeMin}) {
    .show-on-mobile {
      display: none !important;
    }
  }
  @media (max-width: ${theme.mobileLandscapeMax}) {
    .show-on-tablet-or-desktop {
      display: none !important;
    }
  }
  @media (min-width: ${theme.tabletMin}) {
    .hide-on-tablet-or-desktop {
      display: none !important;
    }
  }
  @media (max-width: ${theme.tabletMax}) {
    .show-on-desktop {
      display: none !important;
    }
  }
  @media (min-width: ${theme.desktopMin}) {
    .hide-on-desktop {
      display: none !important;
    }
  }
  @media not print {
    .show-in-print {
      display: none !important;
    }
  }
  @media print {
    .hide-in-print {
      display: none !important;
    }
  }

  ${
    /*
      .m-medium {
        margin: var(--spacing-3);
      }
    */
    [
      generateSpacingStyles("m-", ["margin"]),
      generateSpacingStyles("ml-", ["margin-left"]),
      generateSpacingStyles("mr-", ["margin-right"]),
      generateSpacingStyles("mx-", ["margin-left", "margin-right"]),
      generateSpacingStyles("mb-", ["margin-bottom"]),
      generateSpacingStyles("mt-", ["margin-top"]),
      generateSpacingStyles("my-", ["margin-bottom", "margin-top"]),

      generateSpacingStyles("p-", ["padding"]),
      generateSpacingStyles("pl-", ["padding-left"]),
      generateSpacingStyles("pr-", ["padding-right"]),
      generateSpacingStyles("px-", ["padding-left", "padding-right"]),
      generateSpacingStyles("pb-", ["padding-bottom"]),
      generateSpacingStyles("pt-", ["padding-top"]),
      generateSpacingStyles("py-", ["padding-bottom", "padding-top"]),
    ].join("\n")
  }

  .m-none { margin-top: 0 !important; margin-bottom: 0 !important; margin-left: 0 !important; margin-right: 0 !important; }
  .mt-none { margin-top: 0 !important; }
  .mb-none { margin-bottom: 0 !important; }
  .my-none { margin-top: 0 !important; margin-bottom: 0 !important; }
  .ml-none { margin-left: 0 !important; }
  .mr-none { margin-right: 0 !important; }
  .mx-none { margin-right: 0 !important; margin-left: 0; }

  .mb-none-last > &:last-child { margin-bottom: 0 !important; }

  .m-auto { margin: auto !important; }
  .my-auto { margin-top: auto !important; margin-bottom: auto !important; }
  .mt-auto { margin-top: auto !important; }
  .mb-auto { margin-bottom: auto !important; }
  .mx-auto { margin-right: auto !important; margin-left: auto !important; }
  .ml-auto { margin-left: auto !important; }
  .mr-auto { margin-right: auto !important; }

  .p-none { padding: 0 !important; }
  .pt-none { padding-top: 0 !important; }
  .pb-none { padding-bottom: 0 !important; }
  .py-none { padding-top: 0!important; padding-bottom: 0 !important; }
  .pl-none { padding-left: 0 !important; }
  .pr-none { padding-right: 0 !important; }
  .px-none { padding-right: 0 !important; padding-left: 0 !important; }

  ${spacingNames
    .map(
      (spacingName, idx) =>
        `
        .space-y-${spacingName} > :not([hidden]) ~ :not([hidden]) {
          margin-top: var(--spacing-${idx});
          margin-bottom: 0;
        }
      `
    )
    .join("")}
  ${spacingNames
    .map(
      (spacingName, idx) =>
        `
        .space-x-${spacingName} > :not([hidden]) ~ :not([hidden]) {
          margin-left: var(--spacing-${idx});
          margin-right: 0;
        }
      `
    )
    .join("")}

    .letter-bubble {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  .border-none {
    border: none;
  }

  .road-framework-dash-border {
    border-left: 3px dashed var(--rising-yellow);
  }

  .border-radius {
    border-radius: var(--border-radius);
  }
  .border-top-radius {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .border-bottom-radius {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .border-bottom-radius-small {
    border-bottom-left-radius: var(--border-radius-small);
    border-bottom-right-radius: var(--border-radius-small);
  }
  .border-top-radius-small {
    border-top-left-radius: var(--border-radius-small);
    border-top-right-radius: var(--border-radius-small);
  }
  .border-radius-circle {
    border-radius: 50%;
  }
  .border-radius-xxs {
    border-radius: var(--border-radius-xxs);
  }
  .border-radius-xs {
    border-radius: var(--border-radius-xs);
  }
  .border-radius-small {
    border-radius: var(--border-radius-small);
  }
  .border-radius-medium {
    border-radius: var(--border-radius-medium);
  }
  .border-radius-large {
    border-radius: var(--border-radius-large);
  }
  .border-radius-xl {
    border-radius: var(--border-radius-xl);
  }

  .border-radius-xxl {
    border-radius: var(--border-radius-xxl);
  }

  .border {
    border: 1px solid var(--border-color);
  }
  .border-top {
    border-top: 1px solid var(--border-color);
  }
  .border-top-thick {
    border-top: 8px solid var(--border-color);
  }
  .border-bottom,
  .border-bottom--last:last-child {
    border-bottom: 1px solid var(--border-color);
  }
  .border-bottom-thick {
    border-bottom: 8px solid var(--border-color);
  }
  .border-bottom-none--last:last-child {
    border-bottom: 0;
  }
  .border-right {
    border-right: 1px solid var(--border-color);
  }
  .border-right-thick {
    border-right: 8px solid var(--border-color);
  }
  .border-left {
    border-left: 1px solid var(--border-color);
  }
  .border-left-thick {
    border-left: 8px solid var(--border-color);
  }
  .border-none {
    border-width: 0;
  }

  .border-light {
    border-color: var(--border-light);
  }
  ${
    /*
      .border-gray-1 {
        border-color: var(--gray-1)
      }
    */
    generateStyleText("border-", "border-color", [
      "rising-green",
      "rising-orange",
      "orange-2",
      "orange-3",
      "rising-yellow",
      "rising-yellow",
      "danger",
      "blue-2",
      "white",
      ...generateNumberedSuffix(1, 10, "gray-"),
    ])
  }

  .fg {
    background-color: var(--fg);
  }
  .bg {
    background-color: var(--bg);
  }
  .bg-highlight {
    background-color: rgb(255 210 87 / 7%);
  }
  ${
    /*
      .bg-gray-1 {
        background-color: var(--gray-1);
      }
    */
    generateStyleText("bg-", "background-color", [
      "warning",
      "danger",
      "bright-success",
      "primary",
      "rising-green",
      "rising-orange",
      "orange-2",
      "orange-3",
      "orange-4",
      "rising-yellow",
      "yellow-tint",
      "yellow-off-tint",
      "rising-blue",
      "blue-tint",
      ...generateNumberedSuffix(1, 10, "gray-"),
      "gray-30",
      "white",
      "white-2",
      "green-tint",
      "orange-4-tint",
      "orange-tint",
      "yellow-3",
      "green-2",
      "green-3",
      "green-4",
    ])
  }

  ${
    /*
      .background-linear-gradient-1 {
        background: var(--linear-gradient-1);
      }
    */
    generateStyleText("background-", "background", [
      "linear-gradient-connect",
      "linear-gradient-play",
      "linear-gradient-understand",
      "linear-gradient-unavailable",
    ])
  }

  main {
    height: 100%;

    &.position-left, &.align-left {
      margin-left: 0px;
    }
  }

  .sticky-top {
    position: sticky;
    top: 0;
  }
  .sticky-bottom {
    position: sticky;
    bottom: 0;
  }

  .box-shadow {
    box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.05);
  }
  .more-box-shadow {
    box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
  }
  .blur-2 {
    box-shadow: var(--blur-2);
  }
  .blur-4 {
    box-shadow: var(--blur-4);
  }

  .blur-none {
    box-shadow: none;
  }
  .lift-2 {
    box-shadow: var(--lift-2);
  }
  .lift-4 {
    box-shadow: var(--lift-4);
  }

  button.box-shadow {
    box-shadow: 0px 3px 5px rgba(67, 76, 123, 0.4);
  }
  .less-box-shadow {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08), inset 0px 1px 4px rgba(0, 0, 0, 0.14);
  }

  section {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    padding: var(--spacing-5) var(--spacing-4);
  }

  ul, li, p {
    margin: 0;
    padding: 0;
  }

  ul{
    li{
      margin-bottom: var(--spacing-1);

      &:last-child{
        margin-bottom: 0px;
      }
    }

    li div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  li.bullet-point {
    font-size: 1.7rem;
  }

  .vertical-align-top {
    vertical-align: top;
  }

  .width-auto {
    width: auto;
  }
  form {
    width: 100%;
  }

  textarea {
    color: var(--default);
  }

  input:autofill,
  input:autofill:hover,
  input:autofill:focus-visible textarea:autofill,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus-visible textarea:-webkit-autofill,
  textarea:autofill:hover textarea:autofill:focus-visible,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus-visible,
  select:autofill,
  select:autofill:hover,
  select:autofill:focus-visible,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus-visible {
    // Prevent Webkit autocomplete from changing input background color
    // and other styles; see https://stackoverflow.com/a/29350537
    transition: all 0s 1000000000s; // Chrome, Safari
    background: transparent; // Firefox
  }

  input[type=radio], input[type=checkbox] {
    cursor: pointer;
    min-width: 1.25rem;
    min-height: 1.25rem;
  }

  select{
    border: 0px;
    width: 100% !important;
    cursor: pointer;
    outline: none;
  }

  input[type=number] {
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    min-width: 40px;
    outline: none;
  }

  input[type=range] {
    margin: 0px;
  }

  label {
    cursor: pointer;
  }

  h1, h2, h3 {
    margin: 0;
    padding: 0;
    font-weight: 700;
  }

  h4, h5 {
    margin: 0;
    padding: 0;
    font-weight: 600;
  }

  h5 {
    font-size: 0.9375rem; // ~ 15px
    line-height: 1.5rem;
  }

  h4 {
    font-size: 1.0625rem; // ~ 17px
    line-height: 1.5rem;
  }

  h3 {
    font-size: 1.25rem; // ~ 20px
    line-height: 2rem;
  }

  h2 {
    font-size: 1.625rem; // ~ 26px
    line-height: 2.25rem;
  }

  h1 {
    font-size: 2.25rem; // ~36px
    line-height: 3.5rem;
  }

  b {
    font-weight: 700;
  }

  em {
   color: var(--subtitle)
  }

  .xs-logo-icon {
    width: 14px;
    height: 14px;
  }

  .small-logo-icon {
    width: 24px;
    height: 24px;
  }

  .medium-logo-icon {
    height: 58px;
  }

  .large-logo-icon {
    height: 100px;
    width: 100px;
  }

  .gradient-icon path {
    fill: url('#rising-orange-gradient');
  }

  svg.title-icon {
    width: 48px !important;
    height: 48px !important;
  }
  .display-none {
    display: none;
  }
  .text-nowrap {
    white-space: nowrap;
  }
  .text-prewrap {
    white-space: pre-wrap;
  }
  .flex {
    display: flex;
  }
  .flex-mobile-only {
    display: block;
    @media (max-width: ${theme.mobileLandscapeMax}) {
      display: flex;
    }
  }
  .flex-mobile-never {
    display: block;
    @media not all and (max-width: ${theme.mobileLandscapeMax}) {
      display: flex;
    }
  }
  .flex-basis-0 {
    flex-basis: 0;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .flex-grow-2 {
    flex-grow: 2;
  }
  .flex-grow-3 {
    flex-grow: 3;
  }
  .flex-shrink-0 {
    flex-shrink: 0;
  }

  // NOTE:
  // .theme-widescreen styles below must be defined in separate style blocks so that
  // mobile-only and mobile-never variants can be correctly generated for them

  .theme-widescreen .text-xs {
    font-size: 1rem; // ~16px
    line-height: 1.75rem;
  }

  .theme-widescreen .text-small {
    font-size: 1.5rem; // ~24px
    line-height: 2rem;
  }

  .theme-widescreen .text-medium, .theme-widescreen .text-medium-jumbo-only {
    font-size: 2rem; // ~32px
    line-height: 2.75rem;
  }

  .theme-widescreen .text-large, .theme-widescreen .text-large-jumbo-only {
    font-size: 3rem; // ~48px
    line-height: 4.3rem;
  }

  .theme-widescreen .text-huge, .theme-widescreen .text-huge-jumbo-only {
    font-size: 3.75rem; // ~60px
    line-height: 5.25rem;
  }

  .theme-widescreen .text-field-label {
    font-size: 1.5rem; // ~24px
    line-height: 1.75rem;
  }

  .theme-widescreen .text-field-response {
    font-size: 1.5rem; // ~24px
    line-height: 1.75rem;
  }

  .theme-widescreen .nav-button {
    font-size: 2rem; // ~32px
    line-height: 2.75rem; // ~44px
    padding: 40px 24px;
  }

  .theme-widescreen h5 {
    font-size: 1.0625rem; // ~ 17px
    line-height: 1.5rem;
    font-weight: 600;
  }

  .theme-widescreen h3, .theme-widescreen h4 {
    font-size: 1.5rem; // ~ 24px
    line-height: 2rem;
    font-weight: 600;
  }

  .theme-widescreen h2 {
    font-size: 2rem; // ~32px
    line-height: 2.75rem;
    font-weight: 600;
  }

  .theme-widescreen h1 {
    font-size: 3rem; // ~48px
    line-height: 4.25rem;
    font-weight: 600;
  }

  // Disable all animation when user's prefers-reduced-motion setting is active:
  @media (prefers-reduced-motion) {
    * {
      transition: none !important;
      animation: none !important;
    }
  }

  @media print {
    * {
      // Apply CSS rules to all elements to override standard browser print
      // media behavior of hiding background colors for printer ink conservation.
      // (usually under a "Print Background Colors & Images" browser setting)
      // See: https://developer.mozilla.org/en-US/docs/Web/CSS/print-color-adjust
      print-color-adjust: exact; // Firefox support
      -webkit-print-color-adjust: exact; // Chrome+Safari support
    }

    // Hide entire page "frame" when printing, including header, footer, nav, margins, border, etc.
    .footer {
      display: none;
    }
    html, body, #root, .rtkitview, .main-container, .screen-max-width {
      margin: 0;
      padding: 0;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      background: none;
      max-width: none;
    }

    // Hide text browsers add to header/footer by default such as page URL and page numbering:
    @page {
      size: auto;
      margin: 0;
    }

    // Ensure URL text is never added next to <a> elements when printing:
    a[href]:after {
      content: none !important;
    }
  }
`

const GlobalStyle = createGlobalStyle`${({ theme }) => getThemeStyles(theme)}`

// Utility for constructing global styles scoped by an @media condition.
// This is used to create *-mobile-only and *-mobile-never global style components below.
function createMediaStyles(theme, classes, classSuffix, mediaCondition) {
  // Construct a regex matching text from theme of all requested classes and their
  // associated style rules.
  // NOTE: This only matches classes which don't contain nested { ... } blocks within
  //       their rules. If you need to define mobile variants for classses with nested
  //       blocks, you should do it within the main theme style definitions above.
  const themeStyles = getThemeStyles(theme)
  const mediaStyles = []
  let match
  const classStyleRegex = new RegExp(
    `^\\s*\\.(${classes.join("|")}) \\{[^{}]+\\}`,
    "gm" // g - global, find all matches; m - multiline, match across lines in string
  )
  while (!!(match = classStyleRegex.exec(themeStyles))) {
    mediaStyles.push(match[0].replace(" {", classSuffix + " {"))
  }
  const widescreenClassStyleRegex = new RegExp(
    `^\\s*\\.theme\\-widescreen\\.(${classes.join("|")}) \\{[^{}]+\\}`,
    "gm" // g - global, find all matches; m - multiline, match across lines in string
  )
  while (!!(match = widescreenClassStyleRegex.exec(themeStyles))) {
    mediaStyles.push(match[0].replace(" {", classSuffix + " {"))
  }
  return `
    @media ${mediaCondition} {
      ${mediaStyles.join("\n")}
    }
  `
  // TODO: this while loop could be replaced with string.matchAll version below
  //       if we polyfill string.matchAll (94% browser support). See:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll#regexp.prototype.exec_and_matchall
  // return `
  //   @media ${mediaCondition} {
  //     ${Array.from(getThemeStyles(theme).matchAll(classStyleRegex), (match) =>
  //       match[0].replace(' {', `${classSuffix} {`)
  //     ).join('\n')}
  //   }
  // `
}

// Produces combinations of class parts, eg.
// classVariants(['a', 'b'], ['x', 'y', 'z'])
// >>> ['ax', 'ay', 'az', 'bx', 'by', 'bz']
// classVariants(['a', 'b'], ['x', 'y'], ['1', '2'])
// >>> ['ax1', 'ay1', 'ax2', 'ay2', 'bx1', 'by1', 'bx2', 'by2']
const classVariants = (baseClasses, ...variantLists) =>
  variantLists.reduce(
    (classes, variantList) => variantList.flatMap((variant) => classes.map((baseClass) => baseClass + variant)),
    baseClasses
  )

// Construct an array of all theme classes we want *-mobile-only and *-mobile-never variants for.
// If you need these variants for classes that don't have them yet, add them to the array below.
const mobileVariantClasses = [
  "blur-none",
  "full-width",
  "half-width",
  ...classVariants(["align-self-"], ["center", "start", "end", "stretch"]),
  ...classVariants(["text-"], ["center", "left", "right", "xs", "small", "normal", "medium", "large", "huge"]),
  ...classVariants(["space-x-", "space-y-"], spacingNames),
  ...classVariants(
    ["m", "p", "neg-m", "neg-p"],
    ["-", "l-", "r-", "t-", "b-", "x-", "y-"],
    [...spacingNames, "none", "auto"]
  ),
  ...classVariants(["border-radius-"], ["circle", "xs", "small", "medium", "large", "xl", "xxl"]),
]

// Construct global style component for *-mobile-only styles:
const MobileOnlyStyle = createGlobalStyle`
  ${({ theme }) =>
    createMediaStyles(theme, mobileVariantClasses, "-mobile-only", `(max-width: ${theme.mobileLandscapeMax})`)}
`

// Construct global style component for *-mobile-never styles:
const MobileNeverStyle = createGlobalStyle`
  ${({ theme }) =>
    createMediaStyles(
      theme,
      mobileVariantClasses,
      "-mobile-never",
      `not all and (max-width: ${theme.mobileLandscapeMax})`
    )}
`

const GlobalGradient = () => (
  <svg style={{ height: "0px" }}>
    <defs>
      <linearGradient id="rising-orange-gradient" x2="0" y2="1">
        <stop offset="0%" stopColor="var(--rising-yellow)" />
        <stop offset="63.72%" stopColor="#F97830" />
        <stop offset="100%" stopColor="var(--rising-orange)" />
      </linearGradient>
    </defs>
  </svg>
)

const chartTheme = {
  fontSize: "0.9375rem",
  grid: {
    line: {
      strokeWidth: "2px",
      stroke: "var(--border-color)",
    },
  },
  axis: {
    ticks: {
      text: {
        fontWeight: 500,
        color: "#000",
      },
    },
  },
  label: {
    text: {
      fill: "#494949",
    },
  },
  fontFamily: "Montserrat, sans-serif",
}

const chartColors = [
  "#0A84FF",
  "#50C05C",
  "#8B5DD8",
  "#E137AA",
  "#00A2E0",
  "#00B5A2",
  "#71F4DE",
  "#BD4BC5",
  "#FE9721",
  "#FFD257",
  "#A4CB4B",
  "#48BF8E",
  "#E27DB1",
  "#A2FA12",
  "#FB5DE7",
  "#1FB700",
  "#F67A59",
  "#35C8EF",
  "#7DAC22",
  "#8B9BDC",
  "#F1E736",
  "#F2DAFF",
  "#D1FDBF",
  "#C4937B",
  "#8DA582",
  "#FFB947",
]

const getChartColorMap = (keys) =>
  // Keys are sorted to ensure colors match no matter what order keys are passed in
  zipObject(keys.sort(), chartColors)

const getColorDefault = (keys) => {
  const colorMap = getChartColorMap(keys)
  return ({ key }) => colorMap[key]
}

const getRepeatingColorFunc = (keys, colors, { sortKeys = true } = {}) => {
  // Repeat color list until it matches length of keys:
  const repeatingColors = Array(Math.ceil(keys.length / colors.length))
    .fill(colors)
    .flat()
    .slice(0, keys.length)

  // Shift last color by one if first and last collide:
  // (don't do this if colors list only contains one color, used for some demo charts)
  if (repeatingColors.length > 1 && repeatingColors[0] === repeatingColors[repeatingColors.length - 1]) {
    repeatingColors[repeatingColors.length - 1] = colors[1]
  }

  const sortedKeys = sortKeys ? [...keys].sort() : keys
  const colorMap = zipObject(sortedKeys, repeatingColors)

  return ({ id }) => colorMap[id] ?? "var(--gray-5)"
}

export default theme

export {
  colors,
  GlobalStyle,
  MobileOnlyStyle,
  MobileNeverStyle,
  GlobalGradient,
  chartTheme,
  chartColors,
  getChartColorMap,
  getColorDefault,
  getRepeatingColorFunc,
}
