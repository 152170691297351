import { Navigate, useParams } from "react-router"

import { isUsingSameAuthDomain, redirectWithSameAuthDomainIfNeeded } from "../firebase"
import { useSSOProvider } from "../resource"

import SSOLogin from "./SSOLogin"

import useFeatures from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import { buildUrl, asStringsOrNulls } from "utils/string"

interface ProviderSSOLoginProps {
  ssoRedirectLogin?: boolean
  showLoadingUI?: boolean
}

const ProviderSSOLogin = ({ ssoRedirectLogin = false, showLoadingUI = false }: ProviderSSOLoginProps) => {
  // TODO: jey: remove after we switch all sso providers to same domain
  const features = useFeatures()
  const { provider } = useParams()
  const { next = null } = asStringsOrNulls(useQueryParams())
  const { data: ssoProvider, isFetching } = useSSOProvider(provider ?? "")

  if (!provider) {
    return <Navigate to="/" replace />
  }

  // TODO: jey: replace below after we switch all sso providers to same domain
  // if (!ssoProvider && isFetching) {
  if ((!ssoProvider && isFetching) || features.isInitialLoading) {
    return <Loading />
  }

  // TODO: jey: remove after we switch all sso providers to same domain
  const redirected = redirectWithSameAuthDomainIfNeeded({ features, provider, next })
  if (redirected) {
    return null
  }

  if (ssoRedirectLogin && !showLoadingUI) {
    const redirectLoadingUrl = buildUrl(["auth", "sso", provider, "redirect-loading"], {
      urlQueryParams: {
        next,
        useSameAuthDomain: isUsingSameAuthDomain(),
      },
    })
    return <Navigate to={redirectLoadingUrl} replace state={{ showLoginUI: true }} />
  }

  const providerName = ssoProvider?.sso_provider?.providerName ?? null

  if (!providerName) {
    return <Navigate to="/" replace />
  }

  return <SSOLogin provider={provider} providerName={providerName} ssoRedirectLogin={ssoRedirectLogin} />
}

export default ProviderSSOLogin
