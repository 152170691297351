import type { Property } from "csstype"
import { styled } from "styled-components"
import type { RTTheme } from "types"

interface ViewProps {
  $width?: Property.Width | null
  $minWidth?: Property.MaxWidth | null
  $maxWidth?: Property.MinWidth | null
  $minHeight?: Property.MinHeight | null
  $maxHeight?: Property.MaxHeight | null
  $alignItems?: Property.AlignItems | null
  $alignItemsMobile?: Property.AlignItems | null
  $alignItemsTablet?: Property.AlignItems | null
  $justifyContent?: Property.JustifyContent | null
  $justifyContentMobile?: Property.JustifyContent | null
  $justifyContentTablet?: Property.JustifyContent | null
  $radius?: Property.BorderRadius | null
  $flex?: Property.Flex | null
  $flexDirection?: Property.FlexDirection | null
  $flexDirectionMobile?: Property.FlexDirection | null
  $flexDirectionTablet?: Property.FlexDirection | null
  $flexDirectionWidescreen?: Property.FlexDirection | null
  $flexShrink?: Property.FlexShrink | null
  $flexGrow?: Property.FlexGrow | null
  $flexBasis?: Property.FlexBasis | null
  $flexBasisMobile?: Property.FlexBasis | null
  $flexBasisTablet?: Property.FlexBasis | null
  $flexWrap?: Property.FlexWrap | null
  $height?: Property.Height | null
  $alignSelf?: Property.AlignSelf | null
  $alignContent?: Property.AlignContent | null
  $gridArea?: Property.GridArea | null
  $gap?: Property.Gap | null
  $gapMobile?: Property.Gap | null
  $gapTablet?: Property.Gap | null
}

type PropsWithTheme = ViewProps & { theme: RTTheme }

const View = styled.div<ViewProps>`
  display: flex;
  width: ${({ $width }: PropsWithTheme) => $width};
  min-width: ${({ $minWidth }: PropsWithTheme) => $minWidth};
  max-width: ${({ $maxWidth }: PropsWithTheme) => $maxWidth};
  height: ${({ $height }: PropsWithTheme) => $height};
  min-height: ${({ $minHeight }: PropsWithTheme) => $minHeight};
  max-height: ${({ $maxHeight }: PropsWithTheme) => $maxHeight};
  border-radius: ${({ $radius }: PropsWithTheme) => $radius};
  ${({ $flexDirection, $flexDirectionWidescreen, theme }: PropsWithTheme) =>
    $flexDirectionWidescreen && theme.isWidescreen
      ? `flex-direction: ${$flexDirectionWidescreen};`
      : `flex-direction: ${$flexDirection};`}
  align-items: ${({ $alignItems }: PropsWithTheme) => $alignItems};
  align-content: ${({ $alignContent }: PropsWithTheme) => $alignContent};
  justify-content: ${({ $justifyContent }: PropsWithTheme) => $justifyContent};
  flex-shrink: ${({ $flexShrink }: PropsWithTheme) => $flexShrink};
  flex-grow: ${({ $flexGrow }: PropsWithTheme) => $flexGrow};
  flex-basis: ${({ $flexBasis }: PropsWithTheme) => $flexBasis};
  flex-wrap: ${({ $flexWrap }: PropsWithTheme) => $flexWrap};
  grid-area: ${({ $gridArea }: PropsWithTheme) => $gridArea};
  ${({ $flex }: PropsWithTheme) => `flex: ${$flex};`}
  align-self:  ${({ $alignSelf }: PropsWithTheme) => $alignSelf};
  gap: ${({ $gap }: PropsWithTheme) => $gap};
  /* stylelint-disable-next-line declaration-empty-line-before */

  ${({ $flexDirectionMobile, theme }: PropsWithTheme) =>
    $flexDirectionMobile ? `@media (max-width: ${theme.mobileMax}) { flex-direction: ${$flexDirectionMobile}; }` : ""}

  ${({ $flexDirectionTablet, theme }: PropsWithTheme) =>
    $flexDirectionTablet ? `@media (max-width: ${theme.tabletMax}) { flex-direction: ${$flexDirectionTablet}; }` : ""}

  ${({ $flexBasisMobile, theme }: PropsWithTheme) =>
    $flexBasisMobile ? `@media (max-width: ${theme.mobileMax}) { flex-basis: ${$flexBasisMobile}; }` : ""}


  ${({ $alignItemsMobile, theme }: PropsWithTheme) =>
    $alignItemsMobile ? `@media (max-width: ${theme.mobileMax}) { align-items: ${$alignItemsMobile}; }` : ""}

    ${({ $alignItemsTablet, theme }: PropsWithTheme) =>
    $alignItemsTablet ? `@media (max-width: ${theme.tabletMax}) { align-items: ${$alignItemsTablet}; }` : ""}

  ${({ $justifyContentTablet, theme }: PropsWithTheme) =>
    $justifyContentTablet
      ? `@media (max-width: ${theme.tabletMax}) { justify-content: ${$justifyContentTablet}; }`
      : ""}

  ${({ $justifyContentMobile, theme }: PropsWithTheme) =>
    $justifyContentMobile
      ? `@media (max-width: ${theme.mobileMax}) { justify-content: ${$justifyContentMobile}; }`
      : ""}

  ${({ $gapMobile, theme }: PropsWithTheme) =>
    $gapMobile ? `@media (max-width: ${theme.mobileMax}) { gap: ${$gapMobile}; }` : ""}

  ${({ $gapTablet, theme }: PropsWithTheme) =>
    $gapTablet ? `@media (max-width: ${theme.tabletMax}) { gap: ${$gapTablet}; }` : ""}
`

View.defaultProps = {
  $width: "100%",
  $alignItems: "flex-start",
  $justifyContent: "flex-start",
  $flexDirection: "row",
  $radius: "0",
  $flexShrink: "initial",
  $flexBasis: "initial",
  $flexWrap: "initial",
  $height: "initial",
  $alignSelf: "initial",
  $alignContent: "center",
  $gap: "normal",
}

export default View
export type { ViewProps }
